import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import pass_icon from '../../assets/pwicon.svg';
import mail_icon from '../../assets/mail.svg';
import Pikado from '../../assets/pikado-logo-text.png';
import {doSignIn} from '../../redux/actions';
import {Loader} from '../../components/Loader';
import {Link, useHistory} from 'react-router-dom';
import {validateEmail} from '../../utils/stringUtils';

export const SignIn = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoading = useSelector((state: any) => state.appState.isLoading);
  const [data, setData] = useState<{
    email: string;
    password: string;
    isValidEmail: boolean;
    isValidPass: boolean;
  }>({
    email: '',
    password: '',
    isValidEmail: true,
    isValidPass: true,
  });
  const updateData = (e: any) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const validateInputs = () => {
    if (data.email.trim() != '' && data.password != '') {
      setData({
        ...data,
        isValidEmail: validateEmail(data.email),
        isValidPass: data.password.length >= 6,
      });
      return data.password.length >= 6 && validateEmail(data.email);
    }
    return false;
  };

  const onSignIn = async (e: any) => {
    e.preventDefault();
    if (validateInputs()) {
      //@ts-ignore
      const response = await dispatch(doSignIn(data.email, data.password));
      if (response) {
        history.push('/home');
      }
    }
  };

  return (
    <div className="min-vh-100 d-flex justify-content-center align-items-center bg-white">
      <div className="login-card p-3">
        <div className="pt-2">
          <i className="bi bi-bootstrap"></i>
          <img src={Pikado} alt="Pikado Logo" className="login-logo" />
          <h4 className="my-4 fw-bolder">Sign in to your account</h4>
        </div>
        <form>
          <div className="mb-3">
            <label className="form-label fs-6">
              Your email{' '}
              {!data.isValidEmail && (
                <span className="text-danger"> * Invalid Email</span>
              )}{' '}
            </label>

            <div className="input-group">
              <input
                name="email"
                onChange={updateData}
                type="text"
                className="form-control"
                placeholder="Enter E-Mail"
              />
              <div className="input-group-text">
                <img src={mail_icon} alt="Email Icon" />
              </div>
            </div>
          </div>

          <div className="mb-4">
            <label className="form-label fs-6">Your password </label>
            {!data.isValidPass && (
              <p className="text-danger text-xs">
                Password must be at least 6 characters
              </p>
            )}
            <div className="input-group">
              <input
                name="password"
                type="password"
                onChange={updateData}
                className="form-control"
                placeholder="Enter Password"
              />
              <div className="input-group-text">
                <img src={pass_icon} alt="Password Icon" />
              </div>
            </div>
          </div>
        </form>
        <p className="mb-0 pt-2 h6">
          {' '}
          <Link
            to={'/resetPassword'}
            className="text-pikado-red h6 text-decoration-none fw-bold">
            Forgot your password
          </Link>
        </p>
        <div className="d-grid">
          <button
            onClick={onSignIn}
            type="submit"
            className="auth-btn mt-4"
            disabled={data.email == '' || data.password == ''}>
            Sign in
          </button>
        </div>
        <div className="mt-2">
          <p className="fw-bolder">
            {' '}
            Don't have an account,{' '}
            <span>
              <Link
                to={'/signUp'}
                className="text-pikado-red fw-bold text-decoration-none text-pikado-red ">
                sign up
              </Link>{' '}
            </span>
          </p>
        </div>
      </div>
      {isLoading && <Loader title="Loading" />}
    </div>
  );
};
