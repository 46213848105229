import {useState, useEffect, useRef} from 'react';
import './reportPage.css';
import {Typography} from 'antd';
import AudioIcon from '../../assets/AudioIcon.svg';

const AudioMediaPlayer = ({
  answerId,
  title,
  src,
  currentPlayingId,
  onPlay,
  isAudio,
}: {
  answerId: string;
  currentPlayingId: string | null;
  title: string;
  src: string;
  stopPlayback: boolean;
  isPlaying: boolean;
  onPlay: (s: string) => void;
  isAudio: boolean;
}) => {
  const [progress, setProgress] = useState(0);
  const [isLocallyPlaying, setIsLocallyPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const {Text} = Typography;

  useEffect(() => {
    const audio = new Audio(src);
    audioRef.current = audio;

    const handleTimeUpdate = () => {
      const progress = (audio.currentTime / audio.duration) * 100 || 0;
      setProgress(progress);
    };

    const handleEnded = () => {
      setProgress(0);
      setIsLocallyPlaying(false);
    };

    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('ended', handleEnded);

    return () => {
      audio.removeEventListener('timeupdate', handleTimeUpdate);
      audio.removeEventListener('ended', handleEnded);
      audio.pause();
    };
  }, [src]);

  useEffect(() => {
    const shouldPlay = currentPlayingId === answerId && isLocallyPlaying;
    if (shouldPlay) {
      audioRef.current
        ?.play()
        .catch((error) =>
          console.error('Error attempting to play audio:', error),
        );
    } else {
      audioRef.current?.pause();
    }
  }, [currentPlayingId, isLocallyPlaying, answerId]);

  const togglePlayPause = () => {
    if (currentPlayingId === answerId && isLocallyPlaying) {
      setIsLocallyPlaying(false);
    } else {
      setIsLocallyPlaying(true);
      onPlay(answerId);
    }
  };

  const inlineStyle = {
    background: `linear-gradient(to right, #40B2F2 ${progress}%, #F7F7F7 ${progress}%)`,
    cursor: isAudio ? 'pointer' : 'default',
  };

  return (
    <div style={{width: '70%', maxWidth: '700px', margin: 'auto'}}>
      <div
        style={inlineStyle}
        onClick={isAudio ? togglePlayPause : undefined}
        className="audio-progress-bar">
        <Text
          style={{
            fontFamily: 'Roboto',
            fontSize: 12,
          }}>
          {isAudio ? title : `${title} (Comprehension)`}
        </Text>
        {isAudio && <img src={AudioIcon} alt="audio-icon" />}
      </div>
    </div>
  );
};

export default AudioMediaPlayer;
