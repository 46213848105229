import React, {useEffect, useState, useCallback} from 'react';
import {AnyAction} from 'redux';
import {useDispatch, useSelector} from 'react-redux';
import SmallBoy from '../../assets/Icons/Boy02.svg';
import SmallGirl from '../../assets/Icons/Girl02.svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {createStudent, isLoadingRequest} from '../../redux/actions';

import {Gender, User} from '../../lib';

import moment from 'moment';
import type {targetAge} from '../../redux/reducers/student';
import {ThunkDispatch} from 'redux-thunk';
import Swal from 'sweetalert2';
import {useHistory} from 'react-router-dom';

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

type MyGender = {
  type: Gender;
  isSelected: boolean;
};

export const StudentDetails = (targetStudentAge: any) => {
  const targetAgee: targetAge = targetStudentAge.match.params.targetStudentAge;
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const [selectedDate, setSelectedDate] = useState<Date | string | undefined>();
  const [open, setOpen] = useState<boolean>(false);
  const [kidName, setKidName] = useState<string>('');
  const [selectedGender, setSelectedGender] = useState<Array<MyGender>>([
    {
      type: Gender.MALE,
      isSelected: true,
    },
    {
      type: Gender.FEMALE,
      isSelected: false,
    },
    {type: Gender.NONE, isSelected: false},
  ]);

  const [isDatePickerVisible, setDatePickerVisibility] =
    useState<boolean>(false);

  const showDatePicker = () => {
    setDatePickerVisibility(true);
  };

  const hideDatePicker = () => {
    setDatePickerVisibility(false);
  };

  const handleConfirm = (date: Date | null) => {
    console.warn('A date has been picked: ', date);
    const formatedDate: string = moment(date).format('YYYY-MM-DD');
    console.warn('A date has been picked moment: ', formatedDate);
    setSelectedDate(formatedDate);
    hideDatePicker();
  };

  const userState: User | null = useSelector((state: any) => state.user.user);

  const onPressGender = useCallback(
    (index: any) => {
      let newSelected = selectedGender.map((gender) => ({
        ...gender,
        isSelected: false,
      }));

      newSelected[index].isSelected = true;
      setSelectedGender(newSelected);
    },
    [selectedGender],
  );

  const onSaveAndContinue = useCallback(() => {
    let type: MyGender['type'] = Gender.MALE;
    for (let index = 0; index < selectedGender.length; index++) {
      if (selectedGender[index].isSelected) {
        type = selectedGender[index].type;
        break;
      }
    }

    console.log(
      'selectedDate',
      selectedDate,
      '\nkidName',
      kidName,
      '\ntargetAgee',
      targetAgee,
      '\n gender',
      type,
    );
    if (selectedDate && kidName) {
      dispatch(
        createStudent({
          __typename: 'Student',
          dateOfBirth: selectedDate.toString(),
          kidName,
          gender: type,
          targetAge: targetAgee,
          id: '',
          createdAt: '',
          updatedAt: '',
          name: '',
          age: 0,
        }),
      );
      history.push(`/selectLang`);
    } else {
      let errors: Array<string> = [];

      !kidName && errors.push('invalid name');
      !selectedDate && errors.push('please select a date');
      Swal.fire({
        title: 'please complete the fields',
        icon: 'error',
      });
      dispatch(
        isLoadingRequest(false, {
          message: 'PLEASE_COMPLETE_FIELDS',
          errorMessages: errors,
        }),
      );
    }
  }, [dispatch, kidName, selectedDate, selectedGender, targetAgee]);

  return (
    <div className="container p-4">
      <div className="row justify-content-center flex-column align-items-center ">
        <p className="text-danger text-center h3">Add student details</p>
        {/* <div > */}
        <label
          htmlFor="NAME"
          className="d-block w-50 text-start  mx-auto text-muted">
          name{' '}
        </label>
        <input
          value={kidName}
          name="NAME"
          id="NAME"
          type="text"
          placeholder="student name"
          className="w-50  mx-auto mb-3"
          onChange={(event) => setKidName(event.target.value)}
        />
        <p className="d-block w-50 text-start mb-0 mx-auto text-muted">
          birth date
        </p>
        <DatePicker
          onSelect={(date) => handleConfirm(date)}
          dateFormat="yyyy-MM-dd" // Set the format here
          placeholderText="Select a date"
          value={
            selectedDate instanceof Date
              ? moment(selectedDate).format('YYYY-MM-DD')
              : selectedDate
          }
          // maxDate={moment().subtract('years', 1).toDate()}
          // onCancel={hideDatePicker}
          className=" mb-3"
          showYearDropdown={true}
          yearDropdownItemNumber={5}
          showMonthDropdown={true}
          dateFormatCalendar=" "
        />
        <div>
          <p className="d-block w-50 text-start mb-0 mx-auto text-muted">
            gender
          </p>
          <div className=" d-flex  justify-content-center align-items-center mb-3">
            <label className="custom-checkbox">
              <input
                type="radio"
                name="image-select"
                id="radio1"
                onClick={() => onPressGender(0)}
              />
              <img src={SmallBoy} className="image unchecked" alt="unchecked" />
              <span className="radio-button"></span>
            </label>

            <label className="custom-checkbox">
              <input
                type="radio"
                name="image-select"
                id="radio2"
                onClick={() => onPressGender(1)}
              />
              <img src={SmallGirl} className="image checked" alt="checked" />
              <span className="radio-button"></span>
            </label>
          </div>
        </div>
        <button
          title="SAVE_AND_CONTINUE"
          onClick={onSaveAndContinue}
          className="btn-primary btn w-25 mx-auto">
          save and continue
        </button>
      </div>
    </div>
  );
};
