import {IAnswerService} from './interfaces';
import {AnswerRepository} from '../repositories';

export class AnswerService implements IAnswerService {
  answerRepository: AnswerRepository;

  constructor() {
    this.answerRepository = new AnswerRepository();
  }
  // use an object for parameters to avoid conflicts with optional values.
  async createAnswer({
    studentId,
    questionId,
    audioUri,
    text,
    useEnglishAi,
    enrollmentId,
    given,
    homeworkId,
  }: {
    studentId: string;
    questionId: string;
    audioUri: string;
    text: string;
    useEnglishAi: boolean;
    enrollmentId?: string;
    given?: string;
    homeworkId?: string;
  }): Promise<any> {
    let response = await this.answerRepository.createAnswer(
      studentId,
      questionId,
      audioUri,
      text,
      useEnglishAi,
      enrollmentId,
      given,
      homeworkId,
    );
    if (response.analysis) {
      response.analysis = JSON.parse(response.analysis);
    }
    return response;
  }

  async getAnswer(id: string): Promise<any> {
    return await this.answerRepository.getAnswer(id);
  }
}
