import React, {useState} from 'react';
import mail_icon from '../../assets/mail.svg';
import Swal from 'sweetalert2';
import {useDispatch, useSelector} from 'react-redux';
import {doForgetPassword} from '../../redux/actions/auth';
import {useHistory} from 'react-router-dom';
import {validateEmail} from '../../utils/helper';
import {Loader} from '../../components';

export default function ResetPassword() {
  const isLoading = useSelector((state: any) => state.appState.isLoading);
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<boolean>(false);

  const onEmailChange = (email: any) => {
    setEmail(email.trim());
    setError(!validateEmail(email));
  };

  const onForgetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateEmail(email)) {
      if (email != '' && !error) {
        try {
          //@ts-ignore
          dispatch(doForgetPassword(email)).then((response: any) => {
            const params = {
              length: 6,
              route: '/signIn',
              email: email,
              onSubmit: 'resetPassword',
            };
            response && history.push('/verifyOtp', params);
          });
        } catch (error) {
          console.error(
            'doForgetPassword error from ResetPassword Componenet =>',
            error,
          );
          Swal.fire('Error resetting password');
        }
      }
    }
  };

  return (
    <div className="container vh-100 d-flex flex-column justify-content-center align-items-center">
      <form
        className="row g-4 shadow py-4 rounded m-2"
        onSubmit={(e) => onForgetPassword(e)}>
        <h3 className="h1 text-pikado-red text-center fw-bolder">
          Reset your Password
        </h3>
        <div className="col-lg-6  col-md-12  mx-auto ">
          <label htmlFor="resetPasswordEmail">
            E-Mail<span className="text-pikado-red">*</span>
          </label>
          <div className="input-group">
            <div className="input-group-text">
              <i className="bi bi-person-fill">
                <img src={mail_icon} alt="email icon" />
              </i>
            </div>
            <input
              name="email"
              onChange={(e) => onEmailChange(e?.target?.value)}
              type="text"
              className={`form-control ${
                error && email != '' ? 'border-danger' : ''
              }`}
              placeholder="Enter E-Mail"
              id="resetPasswordEmail"
            />
          </div>
        </div>
        <div className="item_center d-flex justify-content-center">
          <div className="col-lg-6 col-md-12">
            {' '}
            <button type="submit" className="btn mt-4 bg_button fw-bold">
              Reset Password
            </button>
          </div>
        </div>
      </form>
      {isLoading && <Loader title={'Loading'} />}
    </div>
  );
}
