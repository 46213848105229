import {Typography, Button, Tabs} from 'antd';
import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import checkMark from '../../assets/check-mark-icon.png';

import {
  Loader,
  ProgressModal,
  QuestionCard,
  TestCard,
  TestModal,
} from '../../components';

import {ExerciseModal} from '../../components/ExerciseModal';
import {
  AssetType,
  ProgramType,
  QUESTION_TYPE,
  Question,
  Status,
  Test,
} from '../../lib';
import {
  doClearTest,
  doSelectQuestion,
  doSelectTest,
  generateRandomExBasedOnWeakSyllables,
} from '../../redux/actions';
import {Homework} from '../../redux/reducers/questionReducers';
import Title from 'antd/es/typography/Title';
import moment from 'moment';
import ResponsiveAppBar from '../../components/Appbar';
import FooterBar from '../../components/Footerbar';
import ReportsHeader from '../../components/ReportsHeader';
import {HomeworkModal} from '../../components/HomeworkModal';

const openAIQuestionAsset: Question = {
  id: '-OPEN-AI',
  createdAt: 'Node.generateCreatedAt()',
  updatedAt: 'Node.generateUpdatedAt()',
  name: 'Daily Reading Paragraph',
  name_i18n: '',
  programType: ProgramType.LANGUAGE,
  summary:
    'Press the below button to generate a reading paragraph depends on your repeated syllables mistakes.',
  summary_i18n: '',
  description:
    'Please press the recording button and read the below sentence in a clear voice.',
  description_i18n: '',
  text: 'selectedSentence',
  asset: {
    __typename: 'Asset',
    id: 'adsss',
    createdAt: '',
    updatedAt: '',
    type: AssetType.PHOTO,
    thumbnailUri:
      'https://s3.eu-west-1.amazonaws.com/assets.pikado.io/photo/337778904_1074114464030879_8325695208240193271_n.jpg',
    fileUri:
      'https://s3.eu-west-1.amazonaws.com/assets.pikado.io/photo/337778904_1074114464030879_8325695208240193271_n.jpg',
    title_i18n: '',
    subTitle_i18n: '',
    // position?: number ,
  },
  choices: [],
  keywords: [],
  textAI: 'selectedSentence?.toUpperCase()',
  type: QUESTION_TYPE.READ_SENTENCE,
  __typename: 'Question',
};

export const HomePage = () => {
  const dispatch = useDispatch();
  const teacherPhotoRef = useRef<HTMLDivElement>(null);

  const isLoading = useSelector((state: any) => state.appState.isLoading);
  const todayRandomExercise: Question | null = useSelector(
    (state: any) => state.enrollment.todayRandomExercise,
  );
  const selectedStudent = useSelector(
    (state: any) => state.student.selectedStudent,
  );

  const studentsDetails = useSelector(
    (state: any) => state.student.studentsDetails,
  );

  const [isError, setIsError] = useState<boolean>(false);

  const [isProgressModalVisible, setIsProgressModalVisible] =
    useState<boolean>(false);

  const studentsPlanMap = useSelector(
    (state: any) => state.enrollment.studentPlan,
  );
  const [openExerciseModal, setOpenExerciseModal] = useState<boolean>(false);
  const [openTestModal, setOpenTestModal] = useState<boolean>(false);
  const [openHomeworkModal, setOpenHomeworkModal] = useState<boolean>(false);
  const [selectedHomework, setSelectedHomework] = useState<Homework>();
  const [planDay, setPlanDay] = useState<string>();

  const selectedEnrollment = useSelector(
    (state: any) => state.enrollment.selectedEnrollment,
  );

  useEffect(() => {
    isEnrollmentReady();
    if (teacherPhotoRef?.current) {
      let photo =
        selectedStudent?.school != null &&
        selectedStudent.school?.classesschool?.[0]?.teacher?.profilePicture
          ?.fileUri != undefined
          ? selectedStudent.school.classesschool[0].teacher.profilePicture
              .fileUri
          : require('../../assets/profile-icon.jpg');
      teacherPhotoRef.current.style.backgroundImage = `url(${photo})`;
      teacherPhotoRef.current.style.backgroundSize = 'cover';
      teacherPhotoRef.current.style.backgroundPosition = 'center';
    }
    // console.log("selectedStudent?.school?.classesschool?.[0]?.teacher?.profilePicture?.fileUri",selectedStudent?.school?.classesschool?.[0]?.teacher?.profilePicture?.fileUri,
    // "\n selectedStudent?.school != null",selectedStudent?.school != null, "\n selectedStudent?.school?.classesschool?.[0]?.teacher?.profilePicture?.fileUri && selectedStudent?.school != null",selectedStudent?.school?.classesschool?.[0]?.teacher?.profilePicture?.fileUri || selectedStudent?.school != null
    // )
    if (teacherPhotoRef?.current) {
      let photo =
        selectedStudent?.school != null &&
        selectedStudent.school?.classesschool?.[0]?.teacher?.profilePicture
          ?.fileUri != undefined
          ? selectedStudent.school.classesschool[0].teacher.profilePicture
              .fileUri
          : require('../../assets/profile-icon.jpg');
      teacherPhotoRef.current.style.backgroundImage = `url(${photo})`;
      teacherPhotoRef.current.style.backgroundSize = 'cover';
      teacherPhotoRef.current.style.backgroundPosition = 'center';
    }
  }, [selectedEnrollment]);

  const formatDate = (date: string) => {
    const dateStr = new Date(date);

    return new Intl.DateTimeFormat('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    }).format(dateStr);
  };

  const isEnrollmentReady = () => {
    if (Object.keys(studentsPlanMap)?.length !== 0 && selectedStudent) {
      return true;
    } else if (
      Object?.keys(studentsPlanMap)?.length !== 0 &&
      selectedStudent &&
      selectedEnrollment?.package.isFree
    ) {
      // console.log("selectedEnrollment",selectedEnrollment)
      return true;
    }
  };

  const renderCard = (test: Test, index: number) => {
    // console.log(`test ${index}`,test)
    if (test?.questions?.length! > 1 && test?.type !== 'QUIZ') {
      return (
        <TestCard
          //@ts-ignore
          test={test}
          index={index}
          key={`selectedMapId_${index}`}
          //@ts-ignore
          isBlur={
            (selectedEnrollment?.package?.isFree ||
              selectedEnrollment?.paymentStatus !== Status.DONE) &&
            index > 1
              ? true
              : false
          }
          onClick={() => {
            //@ts-ignore
            dispatch(doSelectTest(test));
            setOpenTestModal(true);
          }}
        />
      );
    } else {
      return (
        <div
          className=""
          style={{display: 'contents'}}
          key={`selectedMapId_${index}`}>
          <QuestionCard
            //@ts-ignore
            isBlur={
              (selectedEnrollment?.package?.isFree ||
                selectedEnrollment?.paymentStatus !== Status.DONE) &&
              index > 0
                ? true
                : false
            }
            //@ts-ignore
            question={test}
            index={index}
            onClick={() => {
              //@ts-ignore
              if (test?.questions?.length === 1) {
                //TODO: Fix questions and tests
                //@ts-ignore
                dispatch(doSelectQuestion(test.questions[0]));
              } else {
                //@ts-ignore
                dispatch(doSelectQuestion(test));
              }
              setOpenExerciseModal(true);
            }}
          />
        </div>
      );
    }
  };
  const homeTabs = (title: string) => {
    switch (title) {
      case 'Homework':
        return (
          <div className="container">
            {selectedStudent?.classschool?.homeworks?.length !== 0 && (
              <>
                <Typography
                  color="textSecondary"
                  style={{
                    fontWeight: 'bold',
                    fontSize: 18,
                    marginBottom: 20,
                    color: 'red',
                  }}
                  className="row text-custom-grey flex-shrink-1 justify-content-start align-items-start align-content-start mt-1">
                  TEACHER HOMEWORK
                </Typography>
                {selectedStudent?.classschool?.homeworks?.map(
                  (homework: Homework) => {
                    const expiryDate = moment(homework.expiryDate);
                    const currentDate = moment();
                    const isExpired = expiryDate.isBefore(currentDate);
                    const highestScore = homework.answers.length
                      ? Math.max(
                          //@ts-ignore
                          ...homework.answers.map((answer) => answer.score),
                        )
                      : 0;
                    return (
                      homework.isActive && (
                        <div
                          key={homework.id}
                          className="row w-100 mb-2 homework-background">
                          <div className="col-md-6 col-12 py-2">
                            <div className="row align-items-center m-1">
                              <div className="col-11 d-flex flex-column align-items-start justify-content-center">
                                <p className="mb-0 text-custom-grey">
                                  Homework date:{' '}
                                  <span className="progress-value">
                                    {formatDate(homework.question.createdAt)}
                                  </span>
                                </p>
                                <p className="mb-0 text-custom-grey">
                                  Homework Expiration date:{' '}
                                  <span className="progress-value text-danger ">
                                    {formatDate(homework.expiryDate)}
                                  </span>
                                </p>
                                <p className="mb-0">
                                  Press the button and answer the teacher’s
                                  homework.
                                </p>
                              </div>
                              <div className="col-1  d-flex align-items-center justify-content-center">
                                {homework.answers.length > 0 && (
                                  <img
                                    src={checkMark}
                                    className="img-fluid  mx-auto"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2 col-6 py-2 d-flex justify-content-md-center justify-content-start align-items-center">
                            <div className="text-center">
                              <p className="mb-0 fs-6">
                                Trials {homework.answers.length}
                              </p>
                              <p className="mb-0 fs-6">Score: {highestScore}</p>
                              {isExpired && (
                                <p className="text-danger fs-7 fw-bolder mb-0">
                                  EXPIRED
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4 col-6 px-0 pe-3  d-flex align-items-center justify-content-center homework-background">
                            <Button
                              className="btn-record rounded-0"
                              disabled={
                                homework.answers.length >= 3 || isExpired
                              }
                              onClick={() => {
                                setSelectedHomework(homework);
                                setOpenHomeworkModal(true);
                              }}>
                              <span> Answer question</span>
                            </Button>
                          </div>
                        </div>
                      )
                    );
                  },
                )}
              </>
            )}
          </div>
        );
      case 'Questions':
        return (
          <div className="container w-100" id="cards_landscape_wrap-2">
            <div className="row gx-6 justify-content-center justify-content-md-between align-items-center p-2">
              {isEnrollmentReady() && (
                <QuestionCard
                  isBlur={
                    selectedEnrollment?.package?.isFree ||
                    selectedEnrollment?.paymentStatus !== Status.DONE
                      ? true
                      : false
                  }
                  question={openAIQuestionAsset}
                  index={10}
                  onClick={async () => {
                    const createdAt = moment(todayRandomExercise?.createdAt);
                    const today = moment();
                    if (
                      !todayRandomExercise ||
                      !today?.isSame(createdAt, 'days')
                    ) {
                      //@ts-ignore
                      await dispatch(generateRandomExBasedOnWeakSyllables());
                      setOpenExerciseModal(true);
                    } else {
                      //@ts-ignore
                      dispatch(doSelectQuestion(todayRandomExercise));
                      setOpenExerciseModal(true);
                    }
                  }}
                />
              )}
              {isEnrollmentReady() && renderTodayPlan()}
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };
  const renderTodayPlan = () => {
    const items = studentsPlanMap[selectedStudent?.id];
    if (!items) return null;
    const videoItems = items
      ?.filter((item: Test) => item.asset.type === 'VIDEO')
      .sort((a: Test, b: Test) => (a?.name ?? '').localeCompare(b?.name ?? ''));
    const otherItems = items
      ?.filter((item: Test) => item.asset.type !== 'VIDEO')
      .sort((a: Test, b: Test) => (a?.name ?? '').localeCompare(b?.name ?? ''));
    return [...videoItems, ...otherItems].map((test: Test, index: number) => {
      return renderCard(test, index);
    });
  };
  const renderHomeTabsContent = () => {
    const allTitles = ['Questions', 'Homework'];
    const shouldRemoveHomeworkTab = !selectedStudent.school;
    console.log(selectedStudent, 'Abla');
    const titles = allTitles.filter(
      (title) => !(title === 'Homework' && shouldRemoveHomeworkTab),
    );

    return titles.map((title) => ({
      label: (
        <p
          style={{
            display: 'block',
            fontFamily: 'Roboto',
            color: '#999999',
            fontSize: 16,
            marginBottom: 0,
          }}>
          {title}
        </p>
      ),
      key: title,
      children: homeTabs(title),
    }));
  };

  return (
    <div className="min-vh-100 d-flex flex-column">
      {!openExerciseModal && !openTestModal && <ResponsiveAppBar />}
      <div className="flex-grow-1 d-flex flex-column">
        <ReportsHeader selectedStudent={selectedStudent} />

        <Tabs
          defaultActiveKey={'1'}
          centered
          className="mb-0 flex-grow-1"
          items={renderHomeTabsContent()}
        />

        {isLoading && <Loader title={'Loading...'} />}
        {isError && <Title>Exercises fetching error, try again ⚠️</Title>}
      </div>

      <FooterBar />

      {openExerciseModal && (
        <ExerciseModal closeModal={() => setOpenExerciseModal(false)} />
      )}
      {openTestModal && (
        <TestModal
          onQuestionsClick={() => {
            setOpenExerciseModal(true);
            setOpenTestModal(false);
          }}
          closeModal={() => setOpenTestModal(false)}
        />
      )}
      {openHomeworkModal && (
        <HomeworkModal
          homework={selectedHomework}
          closeModal={() => {
            setOpenTestModal(false);
            setOpenHomeworkModal(false);
          }}
        />
      )}
      {isProgressModalVisible && (
        <ProgressModal
          onCloseProgressModal={() => setIsProgressModalVisible(false)}
        />
      )}
    </div>
  );
};
