import React from 'react';
import Button from 'react-bootstrap/Button';
import {connect} from 'react-redux';
import RecordRTC from 'recordrtc';

import Countdown from 'react-countdown';

import {
  StorageService,
  AnswerService,
  Student,
  AssetType,
  QUESTION_TYPE,
} from '../../lib';
import './index.css';
import {Modal, Typography} from 'antd';
import {UserState} from '../../redux/reducers/user';
import {StudentState} from '../../redux/reducers/student';
import {ProgramState} from '../../redux/reducers/program';
import {ResultBox} from '../ResultBox';
import {Loader} from '../Loader';
import {AppState} from '../../redux/reducers/appState';
import {isLoadingRequest} from '../../redux/actions';
import {TestState} from '../../redux/reducers/test';
import {QuestionState} from '../../redux/reducers/questionReducers';
import {AudioImage} from '../AudioImage';
import AudioVisualizer from './AudioVisualizer';
import {EnrollmentState} from '../../redux/reducers/enrollment';
import ComprehensionQuestion from './ComprehensionQuestion';
import ComprehensionAnswer from './ComprehensionAnswer';
import RenderVideo from './RenderVideo';

const {Text} = Typography;
interface AnalysisChoices {
  [key: string]: {
    isCorrect: boolean;
    correctChoice: string;
    givenChoice: string;
  };
}

type ExerciseModalState = {
  recordingState: 'none' | 'stop' | 'start';
  recordFile: any;
  exerciseRecordAnswer: any;
  isImageAudioPaused: boolean;
  isRecording: boolean;
  audioURL: string;
  comprehensionAnswer?: AnalysisChoices | null;
};

type ExerciseModalProps = {
  closeModal: () => void;
  user?: UserState;
  student?: StudentState;
  program?: ProgramState;
  appState?: AppState;
  test?: TestState;
  question?: QuestionState;
  enrollment?: EnrollmentState;
  dispatch?: any;
};

export class _ExerciseModal extends React.Component<
  ExerciseModalProps,
  ExerciseModalState
> {
  storageService: StorageService;
  answerService: AnswerService;
  videoPlayerRef: any;
  mediaRecorder: RecordRTC | null = null;
  stream: MediaStream | null = null;
  constructor(props: ExerciseModalProps) {
    super(props);
    this.storageService = new StorageService();
    this.answerService = new AnswerService();
    this.onSendComprehensionAnswer = this.onSendComprehensionAnswer.bind(this);
    this.state = {
      recordingState: 'none',
      recordFile: null,
      exerciseRecordAnswer: null,
      isImageAudioPaused: true,
      isRecording: false,
      audioURL: '',
      comprehensionAnswer: null,
    };
    this.videoPlayerRef = React.createRef();
  }

  componentDidMount() {
    this.videoPlayerRef?.current?.play();
  }

  onSendComprehensionAnswer = async (comprehensionAnswer?: string) => {
    try {
      this.props.dispatch(isLoadingRequest(true));

      const selectedStudent: Student = this.props.student?.selectedStudent!;
      const result = await this.answerService.createAnswer({
        studentId: selectedStudent.id,
        questionId: this.props.question?.question?.id!,
        audioUri: 'undefined',
        text: this.props.question?.question?.textAI!,
        useEnglishAi: false,
        enrollmentId: this.props.enrollment?.selectedEnrollment?.id,
        given: comprehensionAnswer ?? comprehensionAnswer,
      });

      this.props.dispatch(isLoadingRequest(false));
      this.setState({
        exerciseRecordAnswer: result?.analysisChoices
          ? result?.analysisChoices
          : null,
        recordingState: 'none',
      });
    } catch (error: any) {
      console.log('error', error);
      alert('Something went wrong');
      this.setState({
        exerciseRecordAnswer: null,
        recordingState: 'none',
        recordFile: null,
      });
      this.props.dispatch(isLoadingRequest(false));
    }
  };

  async onSendRecord() {
    try {
      this.props.dispatch(isLoadingRequest(true));
      let uploadedAudioUri: any = await this.storageService.uploadAudioFile(
        this.props.user?.user?.id!,
        this.state.recordFile,
        false,
        this.props.question?.question?.id!,
      );

      const selectedStudent: Student = this.props.student?.selectedStudent!;
      const result = await this.answerService.createAnswer({
        studentId: selectedStudent.id,
        questionId: this.props.question?.question?.id!,
        audioUri: uploadedAudioUri,
        text: this.props.question?.question?.text!,
        useEnglishAi: this.props.program?.selectedProgram.supportAi!,
        enrollmentId: this.props.enrollment?.selectedEnrollment?.id,
      });
      this.props.dispatch(isLoadingRequest(false));
      if (!result?.score && result?.score != 0) {
        throw new Error();
      }
      this.setState({
        exerciseRecordAnswer:
          result?.score || result?.score == 0 ? result : null,
        recordingState: 'none',
      });
    } catch (error: any) {
      alert('Something went wrong, Please re-record the sentence again ');
      this.setState({
        exerciseRecordAnswer: null,
        recordingState: 'none',
        recordFile: null,
      });
      this.props.dispatch(isLoadingRequest(false));
      console.log('Uploading audio file error: ', error);
    }
  }

  renderCountdown = ({hours, minutes, seconds, completed}: any) => {
    if (!completed) {
      return <Text style={{marginLeft: 5}}>{seconds}</Text>;
    }
  };

  stopRecording() {
    if (!this.mediaRecorder) return;
    this.mediaRecorder.stopRecording(() => {
      if (!this.mediaRecorder) return;
      let blob = this.mediaRecorder.getBlob();
      let audioURL = URL.createObjectURL(blob);
      let file = this.mediaRecorder.getInternalRecorder();

      this.setState({
        audioURL,
        recordFile: file,
        isRecording: false,
        recordingState: 'stop',
      });

      this.mediaRecorder.reset();
      this.mediaRecorder.destroy();
      this.mediaRecorder = null;

      this.stream?.getTracks().forEach((track) => track.stop());
    });
  }

  renderModalFooter = () => {
    switch (this.state.recordingState) {
      case 'none':
        return this.props.question?.question?.type ===
          'COMPREHENSION' ? null : (
          <div className="Buttons_Recording">
            <Button
              onClick={async () => {
                this.stream = await navigator.mediaDevices.getUserMedia({
                  audio: true,
                });
                this.videoPlayerRef?.current?.pause();

                if (this.stream) {
                  this.mediaRecorder = new RecordRTC(this.stream, {
                    type: 'audio',
                    mimeType: 'audio/wav',
                  });

                  this.mediaRecorder.startRecording();
                  this.setState({isRecording: true, recordingState: 'start'});
                }
              }}
              variant=""
              className="btn-record mt-5">
              <span>Record Sentence</span>
            </Button>
          </div>
        );
      case 'start':
        return (
          <div className="Buttons_Recording">
            <Button
              onClick={() => {
                this.setState({isRecording: false, recordingState: 'stop'});
                this.stopRecording();
              }}
              variant=""
              className="btn-record remove-margin-top">
              <span>Stop recording</span>
            </Button>
            <Countdown
              renderer={this.renderCountdown}
              onComplete={() => {
                this.setState({isRecording: false, recordingState: 'stop'});
                this.stopRecording();
              }}
              date={Date.now() + 15000}
            />
          </div>
        );

      case 'stop':
        return (
          <div className="Buttons_Recording">
            <Button
              style={{marginRight: '10px'}}
              onClick={() => {
                this.setState({
                  recordingState: 'none',
                  recordFile: null,
                });
              }}
              variant=""
              className="btn-repeat mt-5">
              <span>Repeat</span>
            </Button>
            <Button
              onClick={async () => {
                await this.onSendRecord();
              }}
              variant=""
              className="btn-record mt-5">
              <span>Send Record</span>
            </Button>
          </div>
        );
      default:
    }
  };

  renderPhoto = () => {
    return (
      <AudioImage
        key={this.props.question?.question?.correctAsset?.fileUri!}
        style={{height: '300px', width: '45rem'}}
        audioSrc={this.props.question?.question?.correctAsset?.fileUri!}
        imageSrc={this.props.question?.question?.asset?.thumbnailUri!}
        isPaused={this.state.isImageAudioPaused}
        onImageClick={() => {
          this.setState({isImageAudioPaused: !this.state.isImageAudioPaused});
        }}
      />
    );
  };

  renderQuestion = () => {
    switch (this.props.question?.question?.asset?.type) {
      case AssetType.PHOTO:
        return this.renderPhoto();
      case AssetType.VIDEO:
        return (
          <RenderVideo
            thumbnailUri={this.props.question?.question?.asset?.thumbnailUri!}
            fileUri={this.props.question?.question?.asset?.fileUri!}
          />
        );
    }
  };

  render() {
    return (
      <Modal
        closable={false}
        className={'exercise-modal'}
        style={{maxHeight: '100%'}}
        width={'50%'}
        centered={true}
        title={
          this.state.recordingState === 'none'
            ? this.state.exerciseRecordAnswer
              ? 'Thanks for your answer, please press any word to listen to the correct pronunciation and see the detailed syllables and phonetics mistakes.'
              : this.props.question?.question?.description &&
                this.props.question?.question?.type ===
                  QUESTION_TYPE.DESCRIBE_IMAGE_ONLY_PRONUNCIATION
              ? 'Describe the attached image'
              : this.props.question?.question?.description
            : this.state.recordingState === 'stop' &&
              'You can listen to your previous recorded voice and send to analyse or press repeat to record again.'
        }
        open={true}
        onOk={() => {}}
        footer={!this.state.exerciseRecordAnswer && this.renderModalFooter()}
        onCancel={() => {
          if (this.state.recordingState === 'start') {
            setTimeout(() => {
              this.props.closeModal();
            }, 200);
          } else {
            this.props.closeModal();
          }
        }}>
        {!this.state.exerciseRecordAnswer && (
          <div style={{width: '100%'}}>
            <div
              className="Modal_Res_Test"
              style={{
                marginTop: '20px',
                position: 'relative',
              }}>
              {this.state.recordingState === 'none' && this.renderQuestion()}
              {this.stream && this.state.isRecording && (
                <AudioVisualizer audioStream={this.stream} />
              )}
            </div>

            {this.state.recordingState !== 'stop' &&
            this.props.question?.question?.type === 'COMPREHENSION' ? (
              <ComprehensionQuestion
                text={this.props.question?.question?.text || ''}
                choices={JSON.parse(
                  this.props?.question?.question?.customChoices || '',
                )}
                onSendRecord={this.onSendComprehensionAnswer}
              />
            ) : (
              <Text
                className={'test-or-question-text'}
                style={{
                  top: 'auto',
                  display: 'inline-block',
                  alignSelf: 'left',
                  textAlign: 'left',
                  fontSize: 20,
                }}>
                {this.props.question?.question?.type ===
                QUESTION_TYPE.DESCRIBE_IMAGE_ONLY_PRONUNCIATION
                  ? ''
                  : this.props.question?.question?.text}
              </Text>
            )}
          </div>
        )}

        {this.state.recordingState === 'stop' && (
          <div style={{textAlign: 'center', marginTop: 50}}>
            {this.state.audioURL && (
              <audio id="audio" src={this.state.audioURL} controls />
            )}
          </div>
        )}
        {this.state.exerciseRecordAnswer &&
        this.props.question?.question?.type === QUESTION_TYPE.COMPREHENSION ? (
          <ComprehensionAnswer
            originalText={this.props.question?.question?.text || ''}
            analysisChoices={
              typeof this.state.exerciseRecordAnswer === 'string'
                ? JSON.parse(this.state.exerciseRecordAnswer)
                : this.state.exerciseRecordAnswer
            }
            onNextQuestion={() => {
              this.videoPlayerRef = React.createRef();
              this.setState({
                exerciseRecordAnswer: null,
                recordingState: 'none',
                recordFile: null,
              });

              if (
                this.props.question?.question?.asset?.type === AssetType.PHOTO
              ) {
                this.setState({
                  isImageAudioPaused: true,
                });
              } else {
                this.videoPlayerRef?.current?.play();
              }
            }}
            onLastQuestion={() => {
              this.setState({
                exerciseRecordAnswer: null,
                recordingState: 'none',
                recordFile: null,
              });
              this.props.closeModal();
            }}
          />
        ) : (
          this.state.exerciseRecordAnswer &&
          this.props.question?.question?.type !==
            QUESTION_TYPE.COMPREHENSION && (
            <ResultBox
              onLastQuestion={() => {
                this.setState({
                  exerciseRecordAnswer: null,
                  recordingState: 'none',
                  recordFile: null,
                });
                this.props.closeModal();
              }}
              onNextQuestion={() => {
                this.videoPlayerRef = React.createRef();
                this.setState({
                  exerciseRecordAnswer: null,
                  recordingState: 'none',
                  recordFile: null,
                });
                if (
                  this.props.question?.question?.asset?.type === AssetType.PHOTO
                ) {
                  this.setState({
                    isImageAudioPaused: true,
                  });
                } else {
                  this.videoPlayerRef?.current?.play();
                }
              }}
              result={this.state.exerciseRecordAnswer}
            />
          )
        )}
        {this.props.appState?.isLoading && (
          <Loader
            title={'Analysing your record'}
            text={
              'Please relax and wait until we review your answers and show you the results.'
            }
          />
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    student: state.student,
    program: state.program,
    appState: state.appState,
    test: state.test,
    question: state.questionReducers,
    enrollment: state.enrollment,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    dispatch: dispatch,
  };
};

export const ExerciseModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(_ExerciseModal);
