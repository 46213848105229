import {SELECT_QUESTION, RESET_QUESTION} from '../../constants';
import {Question, Answer} from '../../lib';

export type QuestionState = {
  question: Question | null;
};

export const initialState: QuestionState = {
  question: null,
};

export type Homework = {
  expiryDate: string;
  id: string;
  isActive: boolean;
  question: Question;
  answers: [Answer];
};
export default (state = initialState, action: any) => {
  switch (action.type) {
    case SELECT_QUESTION:
      return {
        ...state,
        question: action.payload,
      };

    case RESET_QUESTION:
      return {
        ...state,
        question: null,
      };

    default:
      return state;
  }
};
