/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const subscribeClassWithDiscountId = /* GraphQL */ `mutation SubscribeClassWithDiscountId(
  $classschoolId: String!
  $discountcodeId: String!
  $packageId: String!
) {
  subscribeClassWithDiscountId(
    classschoolId: $classschoolId
    discountcodeId: $discountcodeId
    packageId: $packageId
  )
}
` as GeneratedMutation<
  APITypes.SubscribeClassWithDiscountIdMutationVariables,
  APITypes.SubscribeClassWithDiscountIdMutation
>;
export const generateWordTospeech = /* GraphQL */ `mutation GenerateWordTospeech($input: WordToSpeechGeneration!) {
  generateWordTospeech(input: $input)
}
` as GeneratedMutation<
  APITypes.GenerateWordTospeechMutationVariables,
  APITypes.GenerateWordTospeechMutation
>;
export const activatePaidMembership = /* GraphQL */ `mutation ActivatePaidMembership($membershipId: String!) {
  activatePaidMembership(membershipId: $membershipId) {
    id
    createdAt
    updatedAt
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      isOrganization
      __typename
    }
    package {
      id
      createdAt
      updatedAt
      number
      onlyAI
      name
      name_i18n
      sessionDuration
      description
      benefits
      benefits_i18n
      details
      details_i18n
      isFree
      numberOfSessions
      description_i18n
      isNative
      isActive
      isHidden
      productIAP
      typePackage
      packageDuration
      discountDescription
      isRecommended
      sessionPerWeek
      __typename
    }
    curriculum {
      id
      createdAt
      updatedAt
      name
      totalPlanPeriod
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    paymentStatus
    placementStatus
    isActive
    startDate
    endDate
    program {
      id
      createdAt
      updatedAt
      name
      name_i18n
      flag
      supportAi
      isActive
      __typename
    }
    sessions {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    sessionPerWeek
    isFullFilled
    groups {
      id
      createdAt
      updatedAt
      startDate
      name
      division
      limit
      isGroupHasInstructor
      currentSessionNumber
      isActive
      __typename
    }
    discountcode {
      id
      createdAt
      updatedAt
      codeName
      valuePercentage
      isExpired
      validFrom
      expirationDate
      usage
      numOfUsage
      partnerName
      __typename
    }
    promocode {
      id
      createdAt
      updatedAt
      codeName
      isApplied
      sessionPerWeek
      __typename
    }
    studentaisessions {
      id
      createdAt
      updatedAt
      status
      currentQuestionIndex
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ActivatePaidMembershipMutationVariables,
  APITypes.ActivatePaidMembershipMutation
>;
export const activateEnrollmentSubscription = /* GraphQL */ `mutation ActivateEnrollmentSubscription($input: ActivateEnrollmentInsert!) {
  activateEnrollmentSubscription(input: $input) {
    id
    createdAt
    updatedAt
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      isOrganization
      __typename
    }
    package {
      id
      createdAt
      updatedAt
      number
      onlyAI
      name
      name_i18n
      sessionDuration
      description
      benefits
      benefits_i18n
      details
      details_i18n
      isFree
      numberOfSessions
      description_i18n
      isNative
      isActive
      isHidden
      productIAP
      typePackage
      packageDuration
      discountDescription
      isRecommended
      sessionPerWeek
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    test {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    placementStatus
    paymentStatus
    startDate
    endDate
    slots {
      id
      createdAt
      updatedAt
      name
      slotDuration
      startTime
      endTime
      status
      limit
      current
      day
      __typename
    }
    answer {
      id
      createdAt
      updatedAt
      choice
      given
      type
      audioUri
      text
      score
      analysis
      analysisChoices
      useEnglishAi
      __typename
    }
    selfRating
    curriculum {
      id
      createdAt
      updatedAt
      name
      totalPlanPeriod
      __typename
    }
    discountcode {
      id
      createdAt
      updatedAt
      codeName
      valuePercentage
      isExpired
      validFrom
      expirationDate
      usage
      numOfUsage
      partnerName
      __typename
    }
    promocode {
      id
      createdAt
      updatedAt
      codeName
      isApplied
      sessionPerWeek
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ActivateEnrollmentSubscriptionMutationVariables,
  APITypes.ActivateEnrollmentSubscriptionMutation
>;
export const createStudentaisession = /* GraphQL */ `mutation CreateStudentaisession($input: StudentaisessionInsert!) {
  createStudentaisession(input: $input) {
    id
    createdAt
    updatedAt
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    curriculum {
      id
      createdAt
      updatedAt
      name
      totalPlanPeriod
      __typename
    }
    videosession {
      id
      createdAt
      updatedAt
      duration
      name
      isActive
      __typename
    }
    membership {
      id
      createdAt
      updatedAt
      paymentStatus
      placementStatus
      isActive
      startDate
      endDate
      sessionPerWeek
      isFullFilled
      __typename
    }
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      isOrganization
      __typename
    }
    status
    currentQuestionIndex
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateStudentaisessionMutationVariables,
  APITypes.CreateStudentaisessionMutation
>;
export const updateStudentaisession = /* GraphQL */ `mutation UpdateStudentaisession(
  $id: ID!
  $updatedAt: String!
  $input: StudentaisessionUpdate!
) {
  updateStudentaisession(id: $id, updatedAt: $updatedAt, input: $input) {
    id
    createdAt
    updatedAt
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    curriculum {
      id
      createdAt
      updatedAt
      name
      totalPlanPeriod
      __typename
    }
    videosession {
      id
      createdAt
      updatedAt
      duration
      name
      isActive
      __typename
    }
    membership {
      id
      createdAt
      updatedAt
      paymentStatus
      placementStatus
      isActive
      startDate
      endDate
      sessionPerWeek
      isFullFilled
      __typename
    }
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      isOrganization
      __typename
    }
    status
    currentQuestionIndex
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateStudentaisessionMutationVariables,
  APITypes.UpdateStudentaisessionMutation
>;
export const deleteStudentaisession = /* GraphQL */ `mutation DeleteStudentaisession($id: ID!) {
  deleteStudentaisession(id: $id) {
    id
    createdAt
    updatedAt
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    curriculum {
      id
      createdAt
      updatedAt
      name
      totalPlanPeriod
      __typename
    }
    videosession {
      id
      createdAt
      updatedAt
      duration
      name
      isActive
      __typename
    }
    membership {
      id
      createdAt
      updatedAt
      paymentStatus
      placementStatus
      isActive
      startDate
      endDate
      sessionPerWeek
      isFullFilled
      __typename
    }
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      isOrganization
      __typename
    }
    status
    currentQuestionIndex
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteStudentaisessionMutationVariables,
  APITypes.DeleteStudentaisessionMutation
>;
export const createVideosession = /* GraphQL */ `mutation CreateVideosession($input: VideosessionInsert!) {
  createVideosession(input: $input) {
    id
    createdAt
    updatedAt
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    curriculum {
      id
      createdAt
      updatedAt
      name
      totalPlanPeriod
      __typename
    }
    course {
      id
      createdAt
      updatedAt
      name
      plan
      __typename
    }
    test {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    studentaisessions {
      id
      createdAt
      updatedAt
      status
      currentQuestionIndex
      __typename
    }
    duration
    name
    isActive
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateVideosessionMutationVariables,
  APITypes.CreateVideosessionMutation
>;
export const updateVideosession = /* GraphQL */ `mutation UpdateVideosession(
  $id: ID!
  $updatedAt: String!
  $input: VideosessionUpdate!
) {
  updateVideosession(id: $id, updatedAt: $updatedAt, input: $input) {
    id
    createdAt
    updatedAt
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    curriculum {
      id
      createdAt
      updatedAt
      name
      totalPlanPeriod
      __typename
    }
    course {
      id
      createdAt
      updatedAt
      name
      plan
      __typename
    }
    test {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    studentaisessions {
      id
      createdAt
      updatedAt
      status
      currentQuestionIndex
      __typename
    }
    duration
    name
    isActive
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateVideosessionMutationVariables,
  APITypes.UpdateVideosessionMutation
>;
export const deleteVideosession = /* GraphQL */ `mutation DeleteVideosession($id: ID!) {
  deleteVideosession(id: $id) {
    id
    createdAt
    updatedAt
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    curriculum {
      id
      createdAt
      updatedAt
      name
      totalPlanPeriod
      __typename
    }
    course {
      id
      createdAt
      updatedAt
      name
      plan
      __typename
    }
    test {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    studentaisessions {
      id
      createdAt
      updatedAt
      status
      currentQuestionIndex
      __typename
    }
    duration
    name
    isActive
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteVideosessionMutationVariables,
  APITypes.DeleteVideosessionMutation
>;
export const sendEmails = /* GraphQL */ `mutation SendEmails($id: String) {
  sendEmails(id: $id)
}
` as GeneratedMutation<
  APITypes.SendEmailsMutationVariables,
  APITypes.SendEmailsMutation
>;
export const createSegment = /* GraphQL */ `mutation CreateSegment($input: SegmentInsert!) {
  createSegment(input: $input) {
    id
    createdAt
    updatedAt
    segmantName
    createdPinpointSegmentId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSegmentMutationVariables,
  APITypes.CreateSegmentMutation
>;
export const updateSegment = /* GraphQL */ `mutation UpdateSegment($id: ID!, $updatedAt: String!, $input: SegmentUpdate!) {
  updateSegment(id: $id, updatedAt: $updatedAt, input: $input) {
    id
    createdAt
    updatedAt
    segmantName
    createdPinpointSegmentId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSegmentMutationVariables,
  APITypes.UpdateSegmentMutation
>;
export const deleteSegment = /* GraphQL */ `mutation DeleteSegment($id: ID!) {
  deleteSegment(id: $id) {
    id
    createdAt
    updatedAt
    segmantName
    createdPinpointSegmentId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSegmentMutationVariables,
  APITypes.DeleteSegmentMutation
>;
export const createCampaign = /* GraphQL */ `mutation CreateCampaign($input: CampaignInsert!) {
  createCampaign(input: $input)
}
` as GeneratedMutation<
  APITypes.CreateCampaignMutationVariables,
  APITypes.CreateCampaignMutation
>;
export const getRandomExerciseWeakSyllables = /* GraphQL */ `mutation GetRandomExerciseWeakSyllables($studentId: ID) {
  getRandomExerciseWeakSyllables(studentId: $studentId) {
    id
    createdAt
    updatedAt
    name
    name_i18n
    correctAsset {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    asset {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    videoAISessionUri
    choices
    customChoices
    programType
    keywords
    summary
    summary_i18n
    description
    description_i18n
    text
    textAI
    type
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GetRandomExerciseWeakSyllablesMutationVariables,
  APITypes.GetRandomExerciseWeakSyllablesMutation
>;
export const createCurriculum = /* GraphQL */ `mutation CreateCurriculum($input: CurriculumInsert!) {
  createCurriculum(input: $input) {
    id
    createdAt
    updatedAt
    name
    courses {
      id
      createdAt
      updatedAt
      name
      plan
      __typename
    }
    totalPlanPeriod
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCurriculumMutationVariables,
  APITypes.CreateCurriculumMutation
>;
export const updateCurriculum = /* GraphQL */ `mutation UpdateCurriculum(
  $id: ID!
  $updatedAt: String!
  $input: CurriculumUpdate!
) {
  updateCurriculum(id: $id, updatedAt: $updatedAt, input: $input) {
    id
    createdAt
    updatedAt
    name
    courses {
      id
      createdAt
      updatedAt
      name
      plan
      __typename
    }
    totalPlanPeriod
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCurriculumMutationVariables,
  APITypes.UpdateCurriculumMutation
>;
export const deleteCurriculum = /* GraphQL */ `mutation DeleteCurriculum($id: ID!) {
  deleteCurriculum(id: $id) {
    id
    createdAt
    updatedAt
    name
    courses {
      id
      createdAt
      updatedAt
      name
      plan
      __typename
    }
    totalPlanPeriod
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCurriculumMutationVariables,
  APITypes.DeleteCurriculumMutation
>;
export const createTopic = /* GraphQL */ `mutation CreateTopic($input: TopicInsert!) {
  createTopic(input: $input) {
    id
    createdAt
    updatedAt
    topicName
    topicArn
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTopicMutationVariables,
  APITypes.CreateTopicMutation
>;
export const updateTopic = /* GraphQL */ `mutation UpdateTopic($id: ID!, $updatedAt: String!, $input: TopicUpdate!) {
  updateTopic(id: $id, updatedAt: $updatedAt, input: $input) {
    id
    createdAt
    updatedAt
    topicName
    topicArn
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTopicMutationVariables,
  APITypes.UpdateTopicMutation
>;
export const deleteTopic = /* GraphQL */ `mutation DeleteTopic($id: ID!) {
  deleteTopic(id: $id) {
    id
    createdAt
    updatedAt
    topicName
    topicArn
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTopicMutationVariables,
  APITypes.DeleteTopicMutation
>;
export const generateFullReportAsString = /* GraphQL */ `mutation GenerateFullReportAsString($studentId: String) {
  generateFullReportAsString(studentId: $studentId)
}
` as GeneratedMutation<
  APITypes.GenerateFullReportAsStringMutationVariables,
  APITypes.GenerateFullReportAsStringMutation
>;
export const generateClassFullReport = /* GraphQL */ `mutation GenerateClassFullReport($classschoolId: String) {
  generateClassFullReport(classschoolId: $classschoolId)
}
` as GeneratedMutation<
  APITypes.GenerateClassFullReportMutationVariables,
  APITypes.GenerateClassFullReportMutation
>;
export const feedStatisticStagingData = /* GraphQL */ `mutation FeedStatisticStagingData($deleteOldData: Boolean) {
  feedStatisticStagingData(deleteOldData: $deleteOldData)
}
` as GeneratedMutation<
  APITypes.FeedStatisticStagingDataMutationVariables,
  APITypes.FeedStatisticStagingDataMutation
>;
export const createMonthlyReport = /* GraphQL */ `mutation CreateMonthlyReport($input: MonthlyReportInsert!) {
  createMonthlyReport(input: $input) {
    id
    createdAt
    updatedAt
    month
    average
    mistakes
    attempts
    statistic {
      id
      createdAt
      updatedAt
      scores
      averageScore
      fullReportLastGeneratedDate
      phoneticsAsString
      syllablesAsString
      __typename
    }
    totalScores
    totalAverage
    absenceDays
    performanceRate
    absenceRate
    numOfStudentsInClass
    d1
    d2
    d3
    d4
    d5
    d6
    d7
    d8
    d9
    d10
    d11
    d12
    d13
    d14
    d15
    d16
    d17
    d18
    d19
    d20
    d21
    d22
    d23
    d24
    d25
    d26
    d27
    d28
    d29
    d30
    d31
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMonthlyReportMutationVariables,
  APITypes.CreateMonthlyReportMutation
>;
export const updateMonthlyReport = /* GraphQL */ `mutation UpdateMonthlyReport(
  $id: ID!
  $updatedAt: String!
  $input: MonthlyReportUpdate!
) {
  updateMonthlyReport(id: $id, updatedAt: $updatedAt, input: $input) {
    id
    createdAt
    updatedAt
    month
    average
    mistakes
    attempts
    statistic {
      id
      createdAt
      updatedAt
      scores
      averageScore
      fullReportLastGeneratedDate
      phoneticsAsString
      syllablesAsString
      __typename
    }
    totalScores
    totalAverage
    absenceDays
    performanceRate
    absenceRate
    numOfStudentsInClass
    d1
    d2
    d3
    d4
    d5
    d6
    d7
    d8
    d9
    d10
    d11
    d12
    d13
    d14
    d15
    d16
    d17
    d18
    d19
    d20
    d21
    d22
    d23
    d24
    d25
    d26
    d27
    d28
    d29
    d30
    d31
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMonthlyReportMutationVariables,
  APITypes.UpdateMonthlyReportMutation
>;
export const deleteMonthlyReport = /* GraphQL */ `mutation DeleteMonthlyReport($id: ID!) {
  deleteMonthlyReport(id: $id) {
    id
    createdAt
    updatedAt
    month
    average
    mistakes
    attempts
    statistic {
      id
      createdAt
      updatedAt
      scores
      averageScore
      fullReportLastGeneratedDate
      phoneticsAsString
      syllablesAsString
      __typename
    }
    totalScores
    totalAverage
    absenceDays
    performanceRate
    absenceRate
    numOfStudentsInClass
    d1
    d2
    d3
    d4
    d5
    d6
    d7
    d8
    d9
    d10
    d11
    d12
    d13
    d14
    d15
    d16
    d17
    d18
    d19
    d20
    d21
    d22
    d23
    d24
    d25
    d26
    d27
    d28
    d29
    d30
    d31
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMonthlyReportMutationVariables,
  APITypes.DeleteMonthlyReportMutation
>;
export const generateSignedUrlUploadAudioFile = /* GraphQL */ `mutation GenerateSignedUrlUploadAudioFile($input: AudioFileInsert!) {
  generateSignedUrlUploadAudioFile(input: $input)
}
` as GeneratedMutation<
  APITypes.GenerateSignedUrlUploadAudioFileMutationVariables,
  APITypes.GenerateSignedUrlUploadAudioFileMutation
>;
export const addUserToGroup = /* GraphQL */ `mutation AddUserToGroup($userName: String!, $groupName: String!) {
  addUserToGroup(userName: $userName, groupName: $groupName)
}
` as GeneratedMutation<
  APITypes.AddUserToGroupMutationVariables,
  APITypes.AddUserToGroupMutation
>;
export const removeUserFromGroup = /* GraphQL */ `mutation RemoveUserFromGroup($userName: String!, $groupName: String!) {
  removeUserFromGroup(userName: $userName, groupName: $groupName)
}
` as GeneratedMutation<
  APITypes.RemoveUserFromGroupMutationVariables,
  APITypes.RemoveUserFromGroupMutation
>;
export const addUserToPreInstructorsGroup = /* GraphQL */ `mutation AddUserToPreInstructorsGroup($userName: String!) {
  addUserToPreInstructorsGroup(userName: $userName)
}
` as GeneratedMutation<
  APITypes.AddUserToPreInstructorsGroupMutationVariables,
  APITypes.AddUserToPreInstructorsGroupMutation
>;
export const levelUpGroup = /* GraphQL */ `mutation LevelUpGroup($groupId: String, $levelId: String) {
  levelUpGroup(groupId: $groupId, levelId: $levelId) {
    id
    createdAt
    updatedAt
    startDate
    name
    division
    limit
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    memberships {
      id
      createdAt
      updatedAt
      paymentStatus
      placementStatus
      isActive
      startDate
      endDate
      sessionPerWeek
      isFullFilled
      __typename
    }
    sessions {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    instructor {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    isGroupHasInstructor
    program {
      id
      createdAt
      updatedAt
      name
      name_i18n
      flag
      supportAi
      isActive
      __typename
    }
    slot {
      id
      createdAt
      updatedAt
      name
      slotDuration
      startTime
      endTime
      status
      limit
      current
      day
      __typename
    }
    nextSession {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    perviousSession {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    currentSessionNumber
    conversation {
      createdAt
      updatedAt
      id
      name
      isActive
      __typename
    }
    isActive
    __typename
  }
}
` as GeneratedMutation<
  APITypes.LevelUpGroupMutationVariables,
  APITypes.LevelUpGroupMutation
>;
export const createReviewquestion = /* GraphQL */ `mutation CreateReviewquestion($input: ReviewquestionInsert!) {
  createReviewquestion(input: $input) {
    id
    createdAt
    updatedAt
    question_i18n
    type
    reviewType
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReviewquestionMutationVariables,
  APITypes.CreateReviewquestionMutation
>;
export const updateReviewquestion = /* GraphQL */ `mutation UpdateReviewquestion($id: ID!, $input: ReviewquestionUpdate!) {
  updateReviewquestion(id: $id, input: $input) {
    id
    createdAt
    updatedAt
    question_i18n
    type
    reviewType
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReviewquestionMutationVariables,
  APITypes.UpdateReviewquestionMutation
>;
export const deleteReviewquestion = /* GraphQL */ `mutation DeleteReviewquestion($id: ID!) {
  deleteReviewquestion(id: $id) {
    id
    createdAt
    updatedAt
    question_i18n
    type
    reviewType
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReviewquestionMutationVariables,
  APITypes.DeleteReviewquestionMutation
>;
export const createPromoCodes = /* GraphQL */ `mutation CreatePromoCodes(
  $numOfCodes: Int!
  $packageId: String!
  $sessionPerWeek: Int!
  $schoolId: String
  $classschoolId: String
  $levelId: String
  $curriculumId: String
) {
  createPromoCodes(
    numOfCodes: $numOfCodes
    packageId: $packageId
    sessionPerWeek: $sessionPerWeek
    schoolId: $schoolId
    classschoolId: $classschoolId
    levelId: $levelId
    curriculumId: $curriculumId
  )
}
` as GeneratedMutation<
  APITypes.CreatePromoCodesMutationVariables,
  APITypes.CreatePromoCodesMutation
>;
export const applyPromoCode = /* GraphQL */ `mutation ApplyPromoCode($codeName: String!, $studentId: String) {
  applyPromoCode(codeName: $codeName, studentId: $studentId) {
    id
    createdAt
    updatedAt
    codeName
    isApplied
    package {
      id
      createdAt
      updatedAt
      number
      onlyAI
      name
      name_i18n
      sessionDuration
      description
      benefits
      benefits_i18n
      details
      details_i18n
      isFree
      numberOfSessions
      description_i18n
      isNative
      isActive
      isHidden
      productIAP
      typePackage
      packageDuration
      discountDescription
      isRecommended
      sessionPerWeek
      __typename
    }
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      isOrganization
      __typename
    }
    sessionPerWeek
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    school {
      id
      createdAt
      updatedAt
      nameArabic
      name
      isActive
      __typename
    }
    classschool {
      id
      createdAt
      updatedAt
      name
      schoolYear
      __typename
    }
    curriculum {
      id
      createdAt
      updatedAt
      name
      totalPlanPeriod
      __typename
    }
    membership {
      id
      createdAt
      updatedAt
      paymentStatus
      placementStatus
      isActive
      startDate
      endDate
      sessionPerWeek
      isFullFilled
      __typename
    }
    enrollment {
      id
      createdAt
      updatedAt
      placementStatus
      paymentStatus
      startDate
      endDate
      selfRating
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ApplyPromoCodeMutationVariables,
  APITypes.ApplyPromoCodeMutation
>;
export const syncClasseraStudents = /* GraphQL */ `mutation SyncClasseraStudents($input: ClasseraSchoolInsert!) {
  syncClasseraStudents(input: $input) {
    message
    status
    statusCode
    error
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SyncClasseraStudentsMutationVariables,
  APITypes.SyncClasseraStudentsMutation
>;
export const syncBitrixUsers = /* GraphQL */ `mutation SyncBitrixUsers($input: PotentialUsersInsert!) {
  syncBitrixUsers(input: $input)
}
` as GeneratedMutation<
  APITypes.SyncBitrixUsersMutationVariables,
  APITypes.SyncBitrixUsersMutation
>;
export const validatePartnerAuthUser = /* GraphQL */ `mutation ValidatePartnerAuthUser($input: ValidatePartnerInsert!) {
  validatePartnerAuthUser(input: $input) {
    token
    expirationDate
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ValidatePartnerAuthUserMutationVariables,
  APITypes.ValidatePartnerAuthUserMutation
>;
export const createDiscountCodes = /* GraphQL */ `mutation CreateDiscountCodes($input: DiscountCodeInsert!) {
  createDiscountCodes(input: $input)
}
` as GeneratedMutation<
  APITypes.CreateDiscountCodesMutationVariables,
  APITypes.CreateDiscountCodesMutation
>;
export const applyDiscountCode = /* GraphQL */ `mutation ApplyDiscountCode(
  $codeName: String!
  $studentId: String!
  $packageId: String!
) {
  applyDiscountCode(
    codeName: $codeName
    studentId: $studentId
    packageId: $packageId
  )
}
` as GeneratedMutation<
  APITypes.ApplyDiscountCodeMutationVariables,
  APITypes.ApplyDiscountCodeMutation
>;
export const checkDiscountCodeValidation = /* GraphQL */ `mutation CheckDiscountCodeValidation($codeName: String!, $packageId: String!) {
  checkDiscountCodeValidation(codeName: $codeName, packageId: $packageId) {
    id
    createdAt
    updatedAt
    codeName
    valuePercentage
    package {
      id
      createdAt
      updatedAt
      number
      onlyAI
      name
      name_i18n
      sessionDuration
      description
      benefits
      benefits_i18n
      details
      details_i18n
      isFree
      numberOfSessions
      description_i18n
      isNative
      isActive
      isHidden
      productIAP
      typePackage
      packageDuration
      discountDescription
      isRecommended
      sessionPerWeek
      __typename
    }
    isExpired
    validFrom
    expirationDate
    usage
    numOfUsage
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      isOrganization
      __typename
    }
    school {
      id
      createdAt
      updatedAt
      nameArabic
      name
      isActive
      __typename
    }
    classschool {
      id
      createdAt
      updatedAt
      name
      schoolYear
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    curriculum {
      id
      createdAt
      updatedAt
      name
      totalPlanPeriod
      __typename
    }
    partnerName
    memberships {
      id
      createdAt
      updatedAt
      paymentStatus
      placementStatus
      isActive
      startDate
      endDate
      sessionPerWeek
      isFullFilled
      __typename
    }
    enrollments {
      id
      createdAt
      updatedAt
      placementStatus
      paymentStatus
      startDate
      endDate
      selfRating
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CheckDiscountCodeValidationMutationVariables,
  APITypes.CheckDiscountCodeValidationMutation
>;
export const createTeacher = /* GraphQL */ `mutation CreateTeacher($input: TeacherInsert!) {
  createTeacher(input: $input) {
    id
    createdAt
    updatedAt
    name
    classesschool {
      id
      createdAt
      updatedAt
      name
      schoolYear
      __typename
    }
    school {
      id
      createdAt
      updatedAt
      nameArabic
      name
      isActive
      __typename
    }
    user {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      isOrganization
      __typename
    }
    userName
    email
    profilePicture {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTeacherMutationVariables,
  APITypes.CreateTeacherMutation
>;
export const updateTeacher = /* GraphQL */ `mutation UpdateTeacher($id: ID!, $updatedAt: String!, $input: TeacherUpdate!) {
  updateTeacher(id: $id, updatedAt: $updatedAt, input: $input) {
    id
    createdAt
    updatedAt
    name
    classesschool {
      id
      createdAt
      updatedAt
      name
      schoolYear
      __typename
    }
    school {
      id
      createdAt
      updatedAt
      nameArabic
      name
      isActive
      __typename
    }
    user {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      isOrganization
      __typename
    }
    userName
    email
    profilePicture {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTeacherMutationVariables,
  APITypes.UpdateTeacherMutation
>;
export const deleteTeacher = /* GraphQL */ `mutation DeleteTeacher($id: ID!) {
  deleteTeacher(id: $id) {
    id
    createdAt
    updatedAt
    name
    classesschool {
      id
      createdAt
      updatedAt
      name
      schoolYear
      __typename
    }
    school {
      id
      createdAt
      updatedAt
      nameArabic
      name
      isActive
      __typename
    }
    user {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      isOrganization
      __typename
    }
    userName
    email
    profilePicture {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTeacherMutationVariables,
  APITypes.DeleteTeacherMutation
>;
export const createClassschool = /* GraphQL */ `mutation CreateClassschool($input: ClassschoolInsert!) {
  createClassschool(input: $input) {
    id
    createdAt
    updatedAt
    name
    schoolYear
    students {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      isOrganization
      __typename
    }
    school {
      id
      createdAt
      updatedAt
      nameArabic
      name
      isActive
      __typename
    }
    teacher {
      id
      createdAt
      updatedAt
      name
      userName
      email
      __typename
    }
    statistic {
      id
      createdAt
      updatedAt
      scores
      averageScore
      fullReportLastGeneratedDate
      phoneticsAsString
      syllablesAsString
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    homeworks {
      id
      createdAt
      updatedAt
      expiryDate
      isActive
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateClassschoolMutationVariables,
  APITypes.CreateClassschoolMutation
>;
export const updateClassschool = /* GraphQL */ `mutation UpdateClassschool(
  $id: ID!
  $updatedAt: String!
  $input: ClassschoolUpdate!
) {
  updateClassschool(id: $id, updatedAt: $updatedAt, input: $input) {
    id
    createdAt
    updatedAt
    name
    schoolYear
    students {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      isOrganization
      __typename
    }
    school {
      id
      createdAt
      updatedAt
      nameArabic
      name
      isActive
      __typename
    }
    teacher {
      id
      createdAt
      updatedAt
      name
      userName
      email
      __typename
    }
    statistic {
      id
      createdAt
      updatedAt
      scores
      averageScore
      fullReportLastGeneratedDate
      phoneticsAsString
      syllablesAsString
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    homeworks {
      id
      createdAt
      updatedAt
      expiryDate
      isActive
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateClassschoolMutationVariables,
  APITypes.UpdateClassschoolMutation
>;
export const deleteClassschool = /* GraphQL */ `mutation DeleteClassschool($id: ID!) {
  deleteClassschool(id: $id) {
    id
    createdAt
    updatedAt
    name
    schoolYear
    students {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      isOrganization
      __typename
    }
    school {
      id
      createdAt
      updatedAt
      nameArabic
      name
      isActive
      __typename
    }
    teacher {
      id
      createdAt
      updatedAt
      name
      userName
      email
      __typename
    }
    statistic {
      id
      createdAt
      updatedAt
      scores
      averageScore
      fullReportLastGeneratedDate
      phoneticsAsString
      syllablesAsString
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    homeworks {
      id
      createdAt
      updatedAt
      expiryDate
      isActive
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteClassschoolMutationVariables,
  APITypes.DeleteClassschoolMutation
>;
export const createAdmin = /* GraphQL */ `mutation CreateAdmin($input: AdminInsert!) {
  createAdmin(input: $input) {
    id
    createdAt
    updatedAt
    user {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      isOrganization
      __typename
    }
    lastSeenOnline
    firstName
    lastName
    address
    SSN
    gender
    isActive
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAdminMutationVariables,
  APITypes.CreateAdminMutation
>;
export const updateAdmin = /* GraphQL */ `mutation UpdateAdmin($id: ID!, $updatedAt: String!, $input: AdminUpdate!) {
  updateAdmin(id: $id, updatedAt: $updatedAt, input: $input) {
    id
    createdAt
    updatedAt
    user {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      isOrganization
      __typename
    }
    lastSeenOnline
    firstName
    lastName
    address
    SSN
    gender
    isActive
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAdminMutationVariables,
  APITypes.UpdateAdminMutation
>;
export const createSNSEndPoint = /* GraphQL */ `mutation CreateSNSEndPoint($deviceToken: String, $userId: ID) {
  createSNSEndPoint(deviceToken: $deviceToken, userId: $userId)
}
` as GeneratedMutation<
  APITypes.CreateSNSEndPointMutationVariables,
  APITypes.CreateSNSEndPointMutation
>;
export const getSyllablesByLetters = /* GraphQL */ `mutation GetSyllablesByLetters($lettersList: [String]) {
  getSyllablesByLetters(lettersList: $lettersList) {
    items {
      id
      createdAt
      updatedAt
      letters
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GetSyllablesByLettersMutationVariables,
  APITypes.GetSyllablesByLettersMutation
>;
export const createSyllable = /* GraphQL */ `mutation CreateSyllable($input: SyllableInsert!) {
  createSyllable(input: $input) {
    id
    createdAt
    updatedAt
    letters
    correctSyllable {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSyllableMutationVariables,
  APITypes.CreateSyllableMutation
>;
export const updateSyllable = /* GraphQL */ `mutation UpdateSyllable(
  $id: ID!
  $updatedAt: String!
  $input: SyllableUpdate!
) {
  updateSyllable(id: $id, updatedAt: $updatedAt, input: $input) {
    id
    createdAt
    updatedAt
    letters
    correctSyllable {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSyllableMutationVariables,
  APITypes.UpdateSyllableMutation
>;
export const deleteSyllable = /* GraphQL */ `mutation DeleteSyllable($id: ID!) {
  deleteSyllable(id: $id) {
    id
    createdAt
    updatedAt
    letters
    correctSyllable {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSyllableMutationVariables,
  APITypes.DeleteSyllableMutation
>;
export const createSNSEndPointInstructor = /* GraphQL */ `mutation CreateSNSEndPointInstructor($deviceToken: String, $userId: ID) {
  createSNSEndPointInstructor(deviceToken: $deviceToken, userId: $userId)
}
` as GeneratedMutation<
  APITypes.CreateSNSEndPointInstructorMutationVariables,
  APITypes.CreateSNSEndPointInstructorMutation
>;
export const updateDeviceToken = /* GraphQL */ `mutation UpdateDeviceToken($userId: String, $newDeviceToken: String) {
  updateDeviceToken(userId: $userId, newDeviceToken: $newDeviceToken)
}
` as GeneratedMutation<
  APITypes.UpdateDeviceTokenMutationVariables,
  APITypes.UpdateDeviceTokenMutation
>;
export const publishSNSMessageInTopic = /* GraphQL */ `mutation PublishSNSMessageInTopic(
  $title: String!
  $body: String!
  $topicArn: String!
) {
  publishSNSMessageInTopic(title: $title, body: $body, topicArn: $topicArn)
}
` as GeneratedMutation<
  APITypes.PublishSNSMessageInTopicMutationVariables,
  APITypes.PublishSNSMessageInTopicMutation
>;
export const sendMessage = /* GraphQL */ `mutation SendMessage(
  $conversationId: String
  $title: String
  $body: String
  $targetArn: String
) {
  sendMessage(
    conversationId: $conversationId
    title: $title
    body: $body
    targetArn: $targetArn
  )
}
` as GeneratedMutation<
  APITypes.SendMessageMutationVariables,
  APITypes.SendMessageMutation
>;
export const publishSMSMessage = /* GraphQL */ `mutation PublishSMSMessage($message: String, $phoneNumber: String) {
  publishSMSMessage(message: $message, phoneNumber: $phoneNumber)
}
` as GeneratedMutation<
  APITypes.PublishSMSMessageMutationVariables,
  APITypes.PublishSMSMessageMutation
>;
export const startSession = /* GraphQL */ `mutation StartSession($id: ID!) {
  startSession(id: $id) {
    id
    createdAt
    updatedAt
    name
    slot {
      id
      createdAt
      updatedAt
      name
      slotDuration
      startTime
      endTime
      status
      limit
      current
      day
      __typename
    }
    duration
    startDateTime
    startTime
    endTime
    status
    isRescheduled
    instructor {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    lesson {
      id
      createdAt
      updatedAt
      name
      __typename
    }
    moderatorToken
    jitsiUrl
    rtcUserToken
    rtcInstructorToken
    rtcShareScreenToken
    rtcAdminToken
    zoomUrl
    group {
      id
      createdAt
      updatedAt
      startDate
      name
      division
      limit
      isGroupHasInstructor
      currentSessionNumber
      isActive
      __typename
    }
    memberships {
      id
      createdAt
      updatedAt
      paymentStatus
      placementStatus
      isActive
      startDate
      endDate
      sessionPerWeek
      isFullFilled
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.StartSessionMutationVariables,
  APITypes.StartSessionMutation
>;
export const endSession = /* GraphQL */ `mutation EndSession($id: ID!, $status: Status!) {
  endSession(id: $id, status: $status) {
    id
    createdAt
    updatedAt
    name
    slot {
      id
      createdAt
      updatedAt
      name
      slotDuration
      startTime
      endTime
      status
      limit
      current
      day
      __typename
    }
    duration
    startDateTime
    startTime
    endTime
    status
    isRescheduled
    instructor {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    lesson {
      id
      createdAt
      updatedAt
      name
      __typename
    }
    moderatorToken
    jitsiUrl
    rtcUserToken
    rtcInstructorToken
    rtcShareScreenToken
    rtcAdminToken
    zoomUrl
    group {
      id
      createdAt
      updatedAt
      startDate
      name
      division
      limit
      isGroupHasInstructor
      currentSessionNumber
      isActive
      __typename
    }
    memberships {
      id
      createdAt
      updatedAt
      paymentStatus
      placementStatus
      isActive
      startDate
      endDate
      sessionPerWeek
      isFullFilled
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.EndSessionMutationVariables,
  APITypes.EndSessionMutation
>;
export const rescheduleSession = /* GraphQL */ `mutation RescheduleSession($id: ID!, $startDateTime: String!) {
  rescheduleSession(id: $id, startDateTime: $startDateTime) {
    id
    createdAt
    updatedAt
    name
    slot {
      id
      createdAt
      updatedAt
      name
      slotDuration
      startTime
      endTime
      status
      limit
      current
      day
      __typename
    }
    duration
    startDateTime
    startTime
    endTime
    status
    isRescheduled
    instructor {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    lesson {
      id
      createdAt
      updatedAt
      name
      __typename
    }
    moderatorToken
    jitsiUrl
    rtcUserToken
    rtcInstructorToken
    rtcShareScreenToken
    rtcAdminToken
    zoomUrl
    group {
      id
      createdAt
      updatedAt
      startDate
      name
      division
      limit
      isGroupHasInstructor
      currentSessionNumber
      isActive
      __typename
    }
    memberships {
      id
      createdAt
      updatedAt
      paymentStatus
      placementStatus
      isActive
      startDate
      endDate
      sessionPerWeek
      isFullFilled
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RescheduleSessionMutationVariables,
  APITypes.RescheduleSessionMutation
>;
export const createStudentconversation = /* GraphQL */ `mutation CreateStudentconversation($input: StudentconversationInsert!) {
  createStudentconversation(input: $input) {
    createdAt
    updatedAt
    id
    conversation {
      createdAt
      updatedAt
      id
      name
      isActive
      __typename
    }
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      isOrganization
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateStudentconversationMutationVariables,
  APITypes.CreateStudentconversationMutation
>;
export const updateStudentconversation = /* GraphQL */ `mutation UpdateStudentconversation(
  $id: ID!
  $input: StudentconversationUpdate!
) {
  updateStudentconversation(id: $id, input: $input) {
    createdAt
    updatedAt
    id
    conversation {
      createdAt
      updatedAt
      id
      name
      isActive
      __typename
    }
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      isOrganization
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateStudentconversationMutationVariables,
  APITypes.UpdateStudentconversationMutation
>;
export const deleteStudentconversation = /* GraphQL */ `mutation DeleteStudentconversation($id: ID!) {
  deleteStudentconversation(id: $id) {
    createdAt
    updatedAt
    id
    conversation {
      createdAt
      updatedAt
      id
      name
      isActive
      __typename
    }
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      isOrganization
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteStudentconversationMutationVariables,
  APITypes.DeleteStudentconversationMutation
>;
export const createUserconversation = /* GraphQL */ `mutation CreateUserconversation($input: UserconversationInsert!) {
  createUserconversation(input: $input) {
    createdAt
    updatedAt
    id
    conversation {
      createdAt
      updatedAt
      id
      name
      isActive
      __typename
    }
    user {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      isOrganization
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserconversationMutationVariables,
  APITypes.CreateUserconversationMutation
>;
export const updateUserconversation = /* GraphQL */ `mutation UpdateUserconversation($id: ID!, $input: UserconversationUpdate!) {
  updateUserconversation(id: $id, input: $input) {
    createdAt
    updatedAt
    id
    conversation {
      createdAt
      updatedAt
      id
      name
      isActive
      __typename
    }
    user {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      isOrganization
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserconversationMutationVariables,
  APITypes.UpdateUserconversationMutation
>;
export const deleteUserconversation = /* GraphQL */ `mutation DeleteUserconversation($id: ID!) {
  deleteUserconversation(id: $id) {
    createdAt
    updatedAt
    id
    conversation {
      createdAt
      updatedAt
      id
      name
      isActive
      __typename
    }
    user {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      isOrganization
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserconversationMutationVariables,
  APITypes.DeleteUserconversationMutation
>;
export const createConversation = /* GraphQL */ `mutation CreateConversation($input: ConversationInsert!) {
  createConversation(input: $input) {
    createdAt
    updatedAt
    id
    name
    isActive
    messages {
      content
      createdAt
      updatedAt
      id
      isSent
      senderType
      conversationId
      __typename
    }
    group {
      id
      createdAt
      updatedAt
      startDate
      name
      division
      limit
      isGroupHasInstructor
      currentSessionNumber
      isActive
      __typename
    }
    userconversations {
      createdAt
      updatedAt
      id
      __typename
    }
    studentconversations {
      createdAt
      updatedAt
      id
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateConversationMutationVariables,
  APITypes.CreateConversationMutation
>;
export const updateConversation = /* GraphQL */ `mutation UpdateConversation($id: ID!, $input: ConversationUpdate!) {
  updateConversation(id: $id, input: $input) {
    createdAt
    updatedAt
    id
    name
    isActive
    messages {
      content
      createdAt
      updatedAt
      id
      isSent
      senderType
      conversationId
      __typename
    }
    group {
      id
      createdAt
      updatedAt
      startDate
      name
      division
      limit
      isGroupHasInstructor
      currentSessionNumber
      isActive
      __typename
    }
    userconversations {
      createdAt
      updatedAt
      id
      __typename
    }
    studentconversations {
      createdAt
      updatedAt
      id
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateConversationMutationVariables,
  APITypes.UpdateConversationMutation
>;
export const deleteConversation = /* GraphQL */ `mutation DeleteConversation($id: ID!) {
  deleteConversation(id: $id) {
    createdAt
    updatedAt
    id
    name
    isActive
    messages {
      content
      createdAt
      updatedAt
      id
      isSent
      senderType
      conversationId
      __typename
    }
    group {
      id
      createdAt
      updatedAt
      startDate
      name
      division
      limit
      isGroupHasInstructor
      currentSessionNumber
      isActive
      __typename
    }
    userconversations {
      createdAt
      updatedAt
      id
      __typename
    }
    studentconversations {
      createdAt
      updatedAt
      id
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteConversationMutationVariables,
  APITypes.DeleteConversationMutation
>;
export const createMessage = /* GraphQL */ `mutation CreateMessage($input: MessageInsert!) {
  createMessage(input: $input) {
    content
    createdAt
    updatedAt
    id
    isSent
    senderType
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      isOrganization
      __typename
    }
    user {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      isOrganization
      __typename
    }
    conversationId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMessageMutationVariables,
  APITypes.CreateMessageMutation
>;
export const updateMessage = /* GraphQL */ `mutation UpdateMessage($id: ID!, $input: MessageUpdate!) {
  updateMessage(id: $id, input: $input) {
    content
    createdAt
    updatedAt
    id
    isSent
    senderType
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      isOrganization
      __typename
    }
    user {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      isOrganization
      __typename
    }
    conversationId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMessageMutationVariables,
  APITypes.UpdateMessageMutation
>;
export const deleteMessage = /* GraphQL */ `mutation DeleteMessage($id: ID!) {
  deleteMessage(id: $id) {
    content
    createdAt
    updatedAt
    id
    isSent
    senderType
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      isOrganization
      __typename
    }
    user {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      isOrganization
      __typename
    }
    conversationId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMessageMutationVariables,
  APITypes.DeleteMessageMutation
>;
export const createReview = /* GraphQL */ `mutation CreateReview($input: ReviewInsert!) {
  createReview(input: $input) {
    id
    createdAt
    updatedAt
    reviewType
    instructor {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      isOrganization
      __typename
    }
    session {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    feedbackquestions {
      id
      createdAt
      updatedAt
      stars
      __typename
    }
    comments
    averageRating
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReviewMutationVariables,
  APITypes.CreateReviewMutation
>;
export const updateReview = /* GraphQL */ `mutation UpdateReview($id: ID!, $updatedAt: String!, $input: ReviewUpdate!) {
  updateReview(id: $id, updatedAt: $updatedAt, input: $input) {
    id
    createdAt
    updatedAt
    reviewType
    instructor {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      isOrganization
      __typename
    }
    session {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    feedbackquestions {
      id
      createdAt
      updatedAt
      stars
      __typename
    }
    comments
    averageRating
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReviewMutationVariables,
  APITypes.UpdateReviewMutation
>;
export const deleteReview = /* GraphQL */ `mutation DeleteReview($id: ID!) {
  deleteReview(id: $id) {
    id
    createdAt
    updatedAt
    reviewType
    instructor {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      isOrganization
      __typename
    }
    session {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    feedbackquestions {
      id
      createdAt
      updatedAt
      stars
      __typename
    }
    comments
    averageRating
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReviewMutationVariables,
  APITypes.DeleteReviewMutation
>;
export const createAnswerVideoAISession = /* GraphQL */ `mutation CreateAnswerVideoAISession(
  $id: ID!
  $questionIndex: Int!
  $input: AnswerInsert!
) {
  createAnswerVideoAISession(
    id: $id
    questionIndex: $questionIndex
    input: $input
  ) {
    id
    createdAt
    updatedAt
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      isOrganization
      __typename
    }
    question {
      id
      createdAt
      updatedAt
      name
      name_i18n
      videoAISessionUri
      choices
      customChoices
      programType
      keywords
      summary
      summary_i18n
      description
      description_i18n
      text
      textAI
      type
      __typename
    }
    homework {
      id
      createdAt
      updatedAt
      expiryDate
      isActive
      __typename
    }
    enrollment {
      id
      createdAt
      updatedAt
      placementStatus
      paymentStatus
      startDate
      endDate
      selfRating
      __typename
    }
    monthlyReport {
      id
      createdAt
      updatedAt
      month
      average
      mistakes
      attempts
      totalScores
      totalAverage
      absenceDays
      performanceRate
      absenceRate
      numOfStudentsInClass
      d1
      d2
      d3
      d4
      d5
      d6
      d7
      d8
      d9
      d10
      d11
      d12
      d13
      d14
      d15
      d16
      d17
      d18
      d19
      d20
      d21
      d22
      d23
      d24
      d25
      d26
      d27
      d28
      d29
      d30
      d31
      __typename
    }
    choice
    given
    type
    audioUri
    text
    score
    analysis
    analysisChoices
    useEnglishAi
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAnswerVideoAISessionMutationVariables,
  APITypes.CreateAnswerVideoAISessionMutation
>;
export const createAnswer = /* GraphQL */ `mutation CreateAnswer($input: AnswerInsert!) {
  createAnswer(input: $input) {
    id
    createdAt
    updatedAt
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      isOrganization
      __typename
    }
    question {
      id
      createdAt
      updatedAt
      name
      name_i18n
      videoAISessionUri
      choices
      customChoices
      programType
      keywords
      summary
      summary_i18n
      description
      description_i18n
      text
      textAI
      type
      __typename
    }
    homework {
      id
      createdAt
      updatedAt
      expiryDate
      isActive
      __typename
    }
    enrollment {
      id
      createdAt
      updatedAt
      placementStatus
      paymentStatus
      startDate
      endDate
      selfRating
      __typename
    }
    monthlyReport {
      id
      createdAt
      updatedAt
      month
      average
      mistakes
      attempts
      totalScores
      totalAverage
      absenceDays
      performanceRate
      absenceRate
      numOfStudentsInClass
      d1
      d2
      d3
      d4
      d5
      d6
      d7
      d8
      d9
      d10
      d11
      d12
      d13
      d14
      d15
      d16
      d17
      d18
      d19
      d20
      d21
      d22
      d23
      d24
      d25
      d26
      d27
      d28
      d29
      d30
      d31
      __typename
    }
    choice
    given
    type
    audioUri
    text
    score
    analysis
    analysisChoices
    useEnglishAi
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAnswerMutationVariables,
  APITypes.CreateAnswerMutation
>;
export const updateAnswer = /* GraphQL */ `mutation UpdateAnswer($id: ID!, $updatedAt: String!, $input: AnswerUpdate!) {
  updateAnswer(id: $id, updatedAt: $updatedAt, input: $input) {
    id
    createdAt
    updatedAt
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      isOrganization
      __typename
    }
    question {
      id
      createdAt
      updatedAt
      name
      name_i18n
      videoAISessionUri
      choices
      customChoices
      programType
      keywords
      summary
      summary_i18n
      description
      description_i18n
      text
      textAI
      type
      __typename
    }
    homework {
      id
      createdAt
      updatedAt
      expiryDate
      isActive
      __typename
    }
    enrollment {
      id
      createdAt
      updatedAt
      placementStatus
      paymentStatus
      startDate
      endDate
      selfRating
      __typename
    }
    monthlyReport {
      id
      createdAt
      updatedAt
      month
      average
      mistakes
      attempts
      totalScores
      totalAverage
      absenceDays
      performanceRate
      absenceRate
      numOfStudentsInClass
      d1
      d2
      d3
      d4
      d5
      d6
      d7
      d8
      d9
      d10
      d11
      d12
      d13
      d14
      d15
      d16
      d17
      d18
      d19
      d20
      d21
      d22
      d23
      d24
      d25
      d26
      d27
      d28
      d29
      d30
      d31
      __typename
    }
    choice
    given
    type
    audioUri
    text
    score
    analysis
    analysisChoices
    useEnglishAi
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAnswerMutationVariables,
  APITypes.UpdateAnswerMutation
>;
export const deleteAnswer = /* GraphQL */ `mutation DeleteAnswer($id: ID!) {
  deleteAnswer(id: $id) {
    id
    createdAt
    updatedAt
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      isOrganization
      __typename
    }
    question {
      id
      createdAt
      updatedAt
      name
      name_i18n
      videoAISessionUri
      choices
      customChoices
      programType
      keywords
      summary
      summary_i18n
      description
      description_i18n
      text
      textAI
      type
      __typename
    }
    homework {
      id
      createdAt
      updatedAt
      expiryDate
      isActive
      __typename
    }
    enrollment {
      id
      createdAt
      updatedAt
      placementStatus
      paymentStatus
      startDate
      endDate
      selfRating
      __typename
    }
    monthlyReport {
      id
      createdAt
      updatedAt
      month
      average
      mistakes
      attempts
      totalScores
      totalAverage
      absenceDays
      performanceRate
      absenceRate
      numOfStudentsInClass
      d1
      d2
      d3
      d4
      d5
      d6
      d7
      d8
      d9
      d10
      d11
      d12
      d13
      d14
      d15
      d16
      d17
      d18
      d19
      d20
      d21
      d22
      d23
      d24
      d25
      d26
      d27
      d28
      d29
      d30
      d31
      __typename
    }
    choice
    given
    type
    audioUri
    text
    score
    analysis
    analysisChoices
    useEnglishAi
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAnswerMutationVariables,
  APITypes.DeleteAnswerMutation
>;
export const createAvailability = /* GraphQL */ `mutation CreateAvailability($input: AvailabilityInsert!) {
  createAvailability(input: $input) {
    id
    createdAt
    updatedAt
    instructor {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    startTime
    endTime
    slots {
      id
      createdAt
      updatedAt
      name
      slotDuration
      startTime
      endTime
      status
      limit
      current
      day
      __typename
    }
    day
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAvailabilityMutationVariables,
  APITypes.CreateAvailabilityMutation
>;
export const updateAvailability = /* GraphQL */ `mutation UpdateAvailability(
  $id: ID!
  $updatedAt: String!
  $input: AvailabilityUpdate!
) {
  updateAvailability(id: $id, updatedAt: $updatedAt, input: $input) {
    id
    createdAt
    updatedAt
    instructor {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    startTime
    endTime
    slots {
      id
      createdAt
      updatedAt
      name
      slotDuration
      startTime
      endTime
      status
      limit
      current
      day
      __typename
    }
    day
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAvailabilityMutationVariables,
  APITypes.UpdateAvailabilityMutation
>;
export const deleteAvailability = /* GraphQL */ `mutation DeleteAvailability($id: ID!) {
  deleteAvailability(id: $id) {
    id
    createdAt
    updatedAt
    instructor {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    startTime
    endTime
    slots {
      id
      createdAt
      updatedAt
      name
      slotDuration
      startTime
      endTime
      status
      limit
      current
      day
      __typename
    }
    day
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAvailabilityMutationVariables,
  APITypes.DeleteAvailabilityMutation
>;
export const createCourse = /* GraphQL */ `mutation CreateCourse($input: CourseInsert!) {
  createCourse(input: $input) {
    id
    createdAt
    updatedAt
    name
    program {
      id
      createdAt
      updatedAt
      name
      name_i18n
      flag
      supportAi
      isActive
      __typename
    }
    language {
      id
      createdAt
      updatedAt
      language
      country
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    plan
    lessons {
      id
      createdAt
      updatedAt
      name
      __typename
    }
    videosessions {
      id
      createdAt
      updatedAt
      duration
      name
      isActive
      __typename
    }
    curriculum {
      id
      createdAt
      updatedAt
      name
      totalPlanPeriod
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCourseMutationVariables,
  APITypes.CreateCourseMutation
>;
export const updateCourse = /* GraphQL */ `mutation UpdateCourse($id: ID!, $updatedAt: String!, $input: CourseUpdate!) {
  updateCourse(id: $id, updatedAt: $updatedAt, input: $input) {
    id
    createdAt
    updatedAt
    name
    program {
      id
      createdAt
      updatedAt
      name
      name_i18n
      flag
      supportAi
      isActive
      __typename
    }
    language {
      id
      createdAt
      updatedAt
      language
      country
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    plan
    lessons {
      id
      createdAt
      updatedAt
      name
      __typename
    }
    videosessions {
      id
      createdAt
      updatedAt
      duration
      name
      isActive
      __typename
    }
    curriculum {
      id
      createdAt
      updatedAt
      name
      totalPlanPeriod
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCourseMutationVariables,
  APITypes.UpdateCourseMutation
>;
export const deleteCourse = /* GraphQL */ `mutation DeleteCourse($id: ID!) {
  deleteCourse(id: $id) {
    id
    createdAt
    updatedAt
    name
    program {
      id
      createdAt
      updatedAt
      name
      name_i18n
      flag
      supportAi
      isActive
      __typename
    }
    language {
      id
      createdAt
      updatedAt
      language
      country
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    plan
    lessons {
      id
      createdAt
      updatedAt
      name
      __typename
    }
    videosessions {
      id
      createdAt
      updatedAt
      duration
      name
      isActive
      __typename
    }
    curriculum {
      id
      createdAt
      updatedAt
      name
      totalPlanPeriod
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCourseMutationVariables,
  APITypes.DeleteCourseMutation
>;
export const createEnrollment = /* GraphQL */ `mutation CreateEnrollment($input: EnrollmentInsert!) {
  createEnrollment(input: $input) {
    id
    createdAt
    updatedAt
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      isOrganization
      __typename
    }
    package {
      id
      createdAt
      updatedAt
      number
      onlyAI
      name
      name_i18n
      sessionDuration
      description
      benefits
      benefits_i18n
      details
      details_i18n
      isFree
      numberOfSessions
      description_i18n
      isNative
      isActive
      isHidden
      productIAP
      typePackage
      packageDuration
      discountDescription
      isRecommended
      sessionPerWeek
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    test {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    placementStatus
    paymentStatus
    startDate
    endDate
    slots {
      id
      createdAt
      updatedAt
      name
      slotDuration
      startTime
      endTime
      status
      limit
      current
      day
      __typename
    }
    answer {
      id
      createdAt
      updatedAt
      choice
      given
      type
      audioUri
      text
      score
      analysis
      analysisChoices
      useEnglishAi
      __typename
    }
    selfRating
    curriculum {
      id
      createdAt
      updatedAt
      name
      totalPlanPeriod
      __typename
    }
    discountcode {
      id
      createdAt
      updatedAt
      codeName
      valuePercentage
      isExpired
      validFrom
      expirationDate
      usage
      numOfUsage
      partnerName
      __typename
    }
    promocode {
      id
      createdAt
      updatedAt
      codeName
      isApplied
      sessionPerWeek
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEnrollmentMutationVariables,
  APITypes.CreateEnrollmentMutation
>;
export const updateEnrollment = /* GraphQL */ `mutation UpdateEnrollment(
  $id: ID!
  $updatedAt: String!
  $input: EnrollmentUpdate!
) {
  updateEnrollment(id: $id, updatedAt: $updatedAt, input: $input) {
    id
    createdAt
    updatedAt
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      isOrganization
      __typename
    }
    package {
      id
      createdAt
      updatedAt
      number
      onlyAI
      name
      name_i18n
      sessionDuration
      description
      benefits
      benefits_i18n
      details
      details_i18n
      isFree
      numberOfSessions
      description_i18n
      isNative
      isActive
      isHidden
      productIAP
      typePackage
      packageDuration
      discountDescription
      isRecommended
      sessionPerWeek
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    test {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    placementStatus
    paymentStatus
    startDate
    endDate
    slots {
      id
      createdAt
      updatedAt
      name
      slotDuration
      startTime
      endTime
      status
      limit
      current
      day
      __typename
    }
    answer {
      id
      createdAt
      updatedAt
      choice
      given
      type
      audioUri
      text
      score
      analysis
      analysisChoices
      useEnglishAi
      __typename
    }
    selfRating
    curriculum {
      id
      createdAt
      updatedAt
      name
      totalPlanPeriod
      __typename
    }
    discountcode {
      id
      createdAt
      updatedAt
      codeName
      valuePercentage
      isExpired
      validFrom
      expirationDate
      usage
      numOfUsage
      partnerName
      __typename
    }
    promocode {
      id
      createdAt
      updatedAt
      codeName
      isApplied
      sessionPerWeek
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEnrollmentMutationVariables,
  APITypes.UpdateEnrollmentMutation
>;
export const deleteEnrollment = /* GraphQL */ `mutation DeleteEnrollment($id: ID!) {
  deleteEnrollment(id: $id) {
    id
    createdAt
    updatedAt
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      isOrganization
      __typename
    }
    package {
      id
      createdAt
      updatedAt
      number
      onlyAI
      name
      name_i18n
      sessionDuration
      description
      benefits
      benefits_i18n
      details
      details_i18n
      isFree
      numberOfSessions
      description_i18n
      isNative
      isActive
      isHidden
      productIAP
      typePackage
      packageDuration
      discountDescription
      isRecommended
      sessionPerWeek
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    test {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    placementStatus
    paymentStatus
    startDate
    endDate
    slots {
      id
      createdAt
      updatedAt
      name
      slotDuration
      startTime
      endTime
      status
      limit
      current
      day
      __typename
    }
    answer {
      id
      createdAt
      updatedAt
      choice
      given
      type
      audioUri
      text
      score
      analysis
      analysisChoices
      useEnglishAi
      __typename
    }
    selfRating
    curriculum {
      id
      createdAt
      updatedAt
      name
      totalPlanPeriod
      __typename
    }
    discountcode {
      id
      createdAt
      updatedAt
      codeName
      valuePercentage
      isExpired
      validFrom
      expirationDate
      usage
      numOfUsage
      partnerName
      __typename
    }
    promocode {
      id
      createdAt
      updatedAt
      codeName
      isApplied
      sessionPerWeek
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEnrollmentMutationVariables,
  APITypes.DeleteEnrollmentMutation
>;
export const createGroup = /* GraphQL */ `mutation CreateGroup($input: GroupInsert!) {
  createGroup(input: $input) {
    id
    createdAt
    updatedAt
    startDate
    name
    division
    limit
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    memberships {
      id
      createdAt
      updatedAt
      paymentStatus
      placementStatus
      isActive
      startDate
      endDate
      sessionPerWeek
      isFullFilled
      __typename
    }
    sessions {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    instructor {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    isGroupHasInstructor
    program {
      id
      createdAt
      updatedAt
      name
      name_i18n
      flag
      supportAi
      isActive
      __typename
    }
    slot {
      id
      createdAt
      updatedAt
      name
      slotDuration
      startTime
      endTime
      status
      limit
      current
      day
      __typename
    }
    nextSession {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    perviousSession {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    currentSessionNumber
    conversation {
      createdAt
      updatedAt
      id
      name
      isActive
      __typename
    }
    isActive
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGroupMutationVariables,
  APITypes.CreateGroupMutation
>;
export const updateGroup = /* GraphQL */ `mutation UpdateGroup($id: ID!, $updatedAt: String!, $input: GroupUpdate!) {
  updateGroup(id: $id, updatedAt: $updatedAt, input: $input) {
    id
    createdAt
    updatedAt
    startDate
    name
    division
    limit
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    memberships {
      id
      createdAt
      updatedAt
      paymentStatus
      placementStatus
      isActive
      startDate
      endDate
      sessionPerWeek
      isFullFilled
      __typename
    }
    sessions {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    instructor {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    isGroupHasInstructor
    program {
      id
      createdAt
      updatedAt
      name
      name_i18n
      flag
      supportAi
      isActive
      __typename
    }
    slot {
      id
      createdAt
      updatedAt
      name
      slotDuration
      startTime
      endTime
      status
      limit
      current
      day
      __typename
    }
    nextSession {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    perviousSession {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    currentSessionNumber
    conversation {
      createdAt
      updatedAt
      id
      name
      isActive
      __typename
    }
    isActive
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGroupMutationVariables,
  APITypes.UpdateGroupMutation
>;
export const deleteGroup = /* GraphQL */ `mutation DeleteGroup($id: ID!) {
  deleteGroup(id: $id) {
    id
    createdAt
    updatedAt
    startDate
    name
    division
    limit
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    memberships {
      id
      createdAt
      updatedAt
      paymentStatus
      placementStatus
      isActive
      startDate
      endDate
      sessionPerWeek
      isFullFilled
      __typename
    }
    sessions {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    instructor {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    isGroupHasInstructor
    program {
      id
      createdAt
      updatedAt
      name
      name_i18n
      flag
      supportAi
      isActive
      __typename
    }
    slot {
      id
      createdAt
      updatedAt
      name
      slotDuration
      startTime
      endTime
      status
      limit
      current
      day
      __typename
    }
    nextSession {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    perviousSession {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    currentSessionNumber
    conversation {
      createdAt
      updatedAt
      id
      name
      isActive
      __typename
    }
    isActive
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGroupMutationVariables,
  APITypes.DeleteGroupMutation
>;
export const startGroup = /* GraphQL */ `mutation StartGroup($groupId: String!) {
  startGroup(groupId: $groupId) {
    id
    createdAt
    updatedAt
    startDate
    name
    division
    limit
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    memberships {
      id
      createdAt
      updatedAt
      paymentStatus
      placementStatus
      isActive
      startDate
      endDate
      sessionPerWeek
      isFullFilled
      __typename
    }
    sessions {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    instructor {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    isGroupHasInstructor
    program {
      id
      createdAt
      updatedAt
      name
      name_i18n
      flag
      supportAi
      isActive
      __typename
    }
    slot {
      id
      createdAt
      updatedAt
      name
      slotDuration
      startTime
      endTime
      status
      limit
      current
      day
      __typename
    }
    nextSession {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    perviousSession {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    currentSessionNumber
    conversation {
      createdAt
      updatedAt
      id
      name
      isActive
      __typename
    }
    isActive
    __typename
  }
}
` as GeneratedMutation<
  APITypes.StartGroupMutationVariables,
  APITypes.StartGroupMutation
>;
export const createInstructor = /* GraphQL */ `mutation CreateInstructor($input: InstructorInsert!) {
  createInstructor(input: $input) {
    id
    createdAt
    updatedAt
    user {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      isOrganization
      __typename
    }
    name
    gender
    program {
      id
      createdAt
      updatedAt
      name
      name_i18n
      flag
      supportAi
      isActive
      __typename
    }
    promo {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    sessionsCount
    speakingLanguages
    availabilities {
      id
      createdAt
      updatedAt
      startTime
      endTime
      day
      __typename
    }
    slots {
      id
      createdAt
      updatedAt
      name
      slotDuration
      startTime
      endTime
      status
      limit
      current
      day
      __typename
    }
    sessions {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    isNative
    totalRating
    totalReviews
    paymentMethod
    rating
    isActive
    status
    birthDate
    totalRatingConcentration
    totalRatingCamera
    totalRatingInternet
    totalRatingSessionQuality
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInstructorMutationVariables,
  APITypes.CreateInstructorMutation
>;
export const updateInstructor = /* GraphQL */ `mutation UpdateInstructor(
  $id: ID!
  $updatedAt: String!
  $input: InstructorUpdate!
) {
  updateInstructor(id: $id, updatedAt: $updatedAt, input: $input) {
    id
    createdAt
    updatedAt
    user {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      isOrganization
      __typename
    }
    name
    gender
    program {
      id
      createdAt
      updatedAt
      name
      name_i18n
      flag
      supportAi
      isActive
      __typename
    }
    promo {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    sessionsCount
    speakingLanguages
    availabilities {
      id
      createdAt
      updatedAt
      startTime
      endTime
      day
      __typename
    }
    slots {
      id
      createdAt
      updatedAt
      name
      slotDuration
      startTime
      endTime
      status
      limit
      current
      day
      __typename
    }
    sessions {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    isNative
    totalRating
    totalReviews
    paymentMethod
    rating
    isActive
    status
    birthDate
    totalRatingConcentration
    totalRatingCamera
    totalRatingInternet
    totalRatingSessionQuality
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInstructorMutationVariables,
  APITypes.UpdateInstructorMutation
>;
export const deleteInstructor = /* GraphQL */ `mutation DeleteInstructor($id: ID!) {
  deleteInstructor(id: $id) {
    id
    createdAt
    updatedAt
    user {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      isOrganization
      __typename
    }
    name
    gender
    program {
      id
      createdAt
      updatedAt
      name
      name_i18n
      flag
      supportAi
      isActive
      __typename
    }
    promo {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    sessionsCount
    speakingLanguages
    availabilities {
      id
      createdAt
      updatedAt
      startTime
      endTime
      day
      __typename
    }
    slots {
      id
      createdAt
      updatedAt
      name
      slotDuration
      startTime
      endTime
      status
      limit
      current
      day
      __typename
    }
    sessions {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    isNative
    totalRating
    totalReviews
    paymentMethod
    rating
    isActive
    status
    birthDate
    totalRatingConcentration
    totalRatingCamera
    totalRatingInternet
    totalRatingSessionQuality
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteInstructorMutationVariables,
  APITypes.DeleteInstructorMutation
>;
export const createPreInstructor = /* GraphQL */ `mutation CreatePreInstructor($input: PreInstructorInsert!) {
  createPreInstructor(input: $input) {
    id
    createdAt
    updatedAt
    user {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      isOrganization
      __typename
    }
    name
    gender
    program {
      id
      createdAt
      updatedAt
      name
      name_i18n
      flag
      supportAi
      isActive
      __typename
    }
    promo {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    rating
    sessionsCount
    speakingLanguages
    availabilities {
      id
      createdAt
      updatedAt
      startTime
      endTime
      day
      __typename
    }
    slots {
      id
      createdAt
      updatedAt
      name
      slotDuration
      startTime
      endTime
      status
      limit
      current
      day
      __typename
    }
    sessions {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    isNative
    totalRating
    totalReviews
    isActive
    status
    birthDate
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePreInstructorMutationVariables,
  APITypes.CreatePreInstructorMutation
>;
export const updatePreInstructor = /* GraphQL */ `mutation UpdatePreInstructor(
  $id: ID!
  $updatedAt: String!
  $input: PreInstructorUpdate!
) {
  updatePreInstructor(id: $id, updatedAt: $updatedAt, input: $input) {
    id
    createdAt
    updatedAt
    user {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      isOrganization
      __typename
    }
    name
    gender
    program {
      id
      createdAt
      updatedAt
      name
      name_i18n
      flag
      supportAi
      isActive
      __typename
    }
    promo {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    rating
    sessionsCount
    speakingLanguages
    availabilities {
      id
      createdAt
      updatedAt
      startTime
      endTime
      day
      __typename
    }
    slots {
      id
      createdAt
      updatedAt
      name
      slotDuration
      startTime
      endTime
      status
      limit
      current
      day
      __typename
    }
    sessions {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    isNative
    totalRating
    totalReviews
    isActive
    status
    birthDate
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePreInstructorMutationVariables,
  APITypes.UpdatePreInstructorMutation
>;
export const deletePreInstructor = /* GraphQL */ `mutation DeletePreInstructor($id: ID!) {
  deletePreInstructor(id: $id) {
    id
    createdAt
    updatedAt
    user {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      isOrganization
      __typename
    }
    name
    gender
    program {
      id
      createdAt
      updatedAt
      name
      name_i18n
      flag
      supportAi
      isActive
      __typename
    }
    promo {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    rating
    sessionsCount
    speakingLanguages
    availabilities {
      id
      createdAt
      updatedAt
      startTime
      endTime
      day
      __typename
    }
    slots {
      id
      createdAt
      updatedAt
      name
      slotDuration
      startTime
      endTime
      status
      limit
      current
      day
      __typename
    }
    sessions {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    isNative
    totalRating
    totalReviews
    isActive
    status
    birthDate
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePreInstructorMutationVariables,
  APITypes.DeletePreInstructorMutation
>;
export const createLanguage = /* GraphQL */ `mutation CreateLanguage($input: LanguageInsert!) {
  createLanguage(input: $input) {
    id
    createdAt
    updatedAt
    language
    country
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLanguageMutationVariables,
  APITypes.CreateLanguageMutation
>;
export const updateLanguage = /* GraphQL */ `mutation UpdateLanguage(
  $id: ID!
  $updatedAt: String!
  $input: LanguageUpdate!
) {
  updateLanguage(id: $id, updatedAt: $updatedAt, input: $input) {
    id
    createdAt
    updatedAt
    language
    country
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLanguageMutationVariables,
  APITypes.UpdateLanguageMutation
>;
export const deleteLanguage = /* GraphQL */ `mutation DeleteLanguage($id: ID!) {
  deleteLanguage(id: $id) {
    id
    createdAt
    updatedAt
    language
    country
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLanguageMutationVariables,
  APITypes.DeleteLanguageMutation
>;
export const createLesson = /* GraphQL */ `mutation CreateLesson($input: LessonInsert!) {
  createLesson(input: $input) {
    id
    createdAt
    updatedAt
    name
    summary {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    lessonPDF {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    instructionsPDF {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    tests {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    course {
      id
      createdAt
      updatedAt
      name
      plan
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLessonMutationVariables,
  APITypes.CreateLessonMutation
>;
export const updateLesson = /* GraphQL */ `mutation UpdateLesson($id: ID!, $updatedAt: String!, $input: LessonUpdate!) {
  updateLesson(id: $id, updatedAt: $updatedAt, input: $input) {
    id
    createdAt
    updatedAt
    name
    summary {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    lessonPDF {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    instructionsPDF {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    tests {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    course {
      id
      createdAt
      updatedAt
      name
      plan
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLessonMutationVariables,
  APITypes.UpdateLessonMutation
>;
export const deleteLesson = /* GraphQL */ `mutation DeleteLesson($id: ID!) {
  deleteLesson(id: $id) {
    id
    createdAt
    updatedAt
    name
    summary {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    lessonPDF {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    instructionsPDF {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    tests {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    course {
      id
      createdAt
      updatedAt
      name
      plan
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLessonMutationVariables,
  APITypes.DeleteLessonMutation
>;
export const createLevel = /* GraphQL */ `mutation CreateLevel($input: LevelInsert!) {
  createLevel(input: $input) {
    id
    createdAt
    updatedAt
    name
    number
    score
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLevelMutationVariables,
  APITypes.CreateLevelMutation
>;
export const updateLevel = /* GraphQL */ `mutation UpdateLevel($id: ID!, $updatedAt: String!, $input: LevelUpdate!) {
  updateLevel(id: $id, updatedAt: $updatedAt, input: $input) {
    id
    createdAt
    updatedAt
    name
    number
    score
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLevelMutationVariables,
  APITypes.UpdateLevelMutation
>;
export const deleteLevel = /* GraphQL */ `mutation DeleteLevel($id: ID!) {
  deleteLevel(id: $id) {
    id
    createdAt
    updatedAt
    name
    number
    score
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLevelMutationVariables,
  APITypes.DeleteLevelMutation
>;
export const createAsset = /* GraphQL */ `mutation CreateAsset($input: AssetInsert!) {
  createAsset(input: $input) {
    id
    createdAt
    updatedAt
    type
    thumbnailUri
    fileUri
    title_i18n
    subTitle_i18n
    position
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAssetMutationVariables,
  APITypes.CreateAssetMutation
>;
export const updateAsset = /* GraphQL */ `mutation UpdateAsset($id: ID!, $updatedAt: String!, $input: AssetUpdate!) {
  updateAsset(id: $id, updatedAt: $updatedAt, input: $input) {
    id
    createdAt
    updatedAt
    type
    thumbnailUri
    fileUri
    title_i18n
    subTitle_i18n
    position
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAssetMutationVariables,
  APITypes.UpdateAssetMutation
>;
export const deleteAsset = /* GraphQL */ `mutation DeleteAsset($id: ID!) {
  deleteAsset(id: $id) {
    id
    createdAt
    updatedAt
    type
    thumbnailUri
    fileUri
    title_i18n
    subTitle_i18n
    position
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAssetMutationVariables,
  APITypes.DeleteAssetMutation
>;
export const createMembership = /* GraphQL */ `mutation CreateMembership($input: MembershipInsert!) {
  createMembership(input: $input) {
    id
    createdAt
    updatedAt
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      isOrganization
      __typename
    }
    package {
      id
      createdAt
      updatedAt
      number
      onlyAI
      name
      name_i18n
      sessionDuration
      description
      benefits
      benefits_i18n
      details
      details_i18n
      isFree
      numberOfSessions
      description_i18n
      isNative
      isActive
      isHidden
      productIAP
      typePackage
      packageDuration
      discountDescription
      isRecommended
      sessionPerWeek
      __typename
    }
    curriculum {
      id
      createdAt
      updatedAt
      name
      totalPlanPeriod
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    paymentStatus
    placementStatus
    isActive
    startDate
    endDate
    program {
      id
      createdAt
      updatedAt
      name
      name_i18n
      flag
      supportAi
      isActive
      __typename
    }
    sessions {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    sessionPerWeek
    isFullFilled
    groups {
      id
      createdAt
      updatedAt
      startDate
      name
      division
      limit
      isGroupHasInstructor
      currentSessionNumber
      isActive
      __typename
    }
    discountcode {
      id
      createdAt
      updatedAt
      codeName
      valuePercentage
      isExpired
      validFrom
      expirationDate
      usage
      numOfUsage
      partnerName
      __typename
    }
    promocode {
      id
      createdAt
      updatedAt
      codeName
      isApplied
      sessionPerWeek
      __typename
    }
    studentaisessions {
      id
      createdAt
      updatedAt
      status
      currentQuestionIndex
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMembershipMutationVariables,
  APITypes.CreateMembershipMutation
>;
export const updateMembership = /* GraphQL */ `mutation UpdateMembership(
  $id: ID!
  $updatedAt: String!
  $input: MembershipUpdate!
) {
  updateMembership(id: $id, updatedAt: $updatedAt, input: $input) {
    id
    createdAt
    updatedAt
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      isOrganization
      __typename
    }
    package {
      id
      createdAt
      updatedAt
      number
      onlyAI
      name
      name_i18n
      sessionDuration
      description
      benefits
      benefits_i18n
      details
      details_i18n
      isFree
      numberOfSessions
      description_i18n
      isNative
      isActive
      isHidden
      productIAP
      typePackage
      packageDuration
      discountDescription
      isRecommended
      sessionPerWeek
      __typename
    }
    curriculum {
      id
      createdAt
      updatedAt
      name
      totalPlanPeriod
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    paymentStatus
    placementStatus
    isActive
    startDate
    endDate
    program {
      id
      createdAt
      updatedAt
      name
      name_i18n
      flag
      supportAi
      isActive
      __typename
    }
    sessions {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    sessionPerWeek
    isFullFilled
    groups {
      id
      createdAt
      updatedAt
      startDate
      name
      division
      limit
      isGroupHasInstructor
      currentSessionNumber
      isActive
      __typename
    }
    discountcode {
      id
      createdAt
      updatedAt
      codeName
      valuePercentage
      isExpired
      validFrom
      expirationDate
      usage
      numOfUsage
      partnerName
      __typename
    }
    promocode {
      id
      createdAt
      updatedAt
      codeName
      isApplied
      sessionPerWeek
      __typename
    }
    studentaisessions {
      id
      createdAt
      updatedAt
      status
      currentQuestionIndex
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMembershipMutationVariables,
  APITypes.UpdateMembershipMutation
>;
export const deleteMembership = /* GraphQL */ `mutation DeleteMembership($id: ID!) {
  deleteMembership(id: $id) {
    id
    createdAt
    updatedAt
    student {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      isOrganization
      __typename
    }
    package {
      id
      createdAt
      updatedAt
      number
      onlyAI
      name
      name_i18n
      sessionDuration
      description
      benefits
      benefits_i18n
      details
      details_i18n
      isFree
      numberOfSessions
      description_i18n
      isNative
      isActive
      isHidden
      productIAP
      typePackage
      packageDuration
      discountDescription
      isRecommended
      sessionPerWeek
      __typename
    }
    curriculum {
      id
      createdAt
      updatedAt
      name
      totalPlanPeriod
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    paymentStatus
    placementStatus
    isActive
    startDate
    endDate
    program {
      id
      createdAt
      updatedAt
      name
      name_i18n
      flag
      supportAi
      isActive
      __typename
    }
    sessions {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    sessionPerWeek
    isFullFilled
    groups {
      id
      createdAt
      updatedAt
      startDate
      name
      division
      limit
      isGroupHasInstructor
      currentSessionNumber
      isActive
      __typename
    }
    discountcode {
      id
      createdAt
      updatedAt
      codeName
      valuePercentage
      isExpired
      validFrom
      expirationDate
      usage
      numOfUsage
      partnerName
      __typename
    }
    promocode {
      id
      createdAt
      updatedAt
      codeName
      isApplied
      sessionPerWeek
      __typename
    }
    studentaisessions {
      id
      createdAt
      updatedAt
      status
      currentQuestionIndex
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMembershipMutationVariables,
  APITypes.DeleteMembershipMutation
>;
export const createPackage = /* GraphQL */ `mutation CreatePackage($input: PackageInsert!) {
  createPackage(input: $input) {
    id
    createdAt
    updatedAt
    number
    onlyAI
    name
    name_i18n
    sessionDuration
    program {
      id
      createdAt
      updatedAt
      name
      name_i18n
      flag
      supportAi
      isActive
      __typename
    }
    price {
      id
      createdAt
      updatedAt
      name
      price
      currency
      country
      __typename
    }
    description
    benefits
    benefits_i18n
    promoAsset {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    coverAsset {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    activities {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    records {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    details
    details_i18n
    isFree
    numberOfSessions
    description_i18n
    isNative
    isActive
    isHidden
    enrollmentPackage {
      id
      createdAt
      updatedAt
      number
      onlyAI
      name
      name_i18n
      sessionDuration
      description
      benefits
      benefits_i18n
      details
      details_i18n
      isFree
      numberOfSessions
      description_i18n
      isNative
      isActive
      isHidden
      productIAP
      typePackage
      packageDuration
      discountDescription
      isRecommended
      sessionPerWeek
      __typename
    }
    promocodes {
      id
      createdAt
      updatedAt
      codeName
      isApplied
      sessionPerWeek
      __typename
    }
    productIAP
    typePackage
    packageDuration
    discountDescription
    isRecommended
    sessionPerWeek
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePackageMutationVariables,
  APITypes.CreatePackageMutation
>;
export const updatePackage = /* GraphQL */ `mutation UpdatePackage($id: ID!, $updatedAt: String!, $input: PackageUpdate!) {
  updatePackage(id: $id, updatedAt: $updatedAt, input: $input) {
    id
    createdAt
    updatedAt
    number
    onlyAI
    name
    name_i18n
    sessionDuration
    program {
      id
      createdAt
      updatedAt
      name
      name_i18n
      flag
      supportAi
      isActive
      __typename
    }
    price {
      id
      createdAt
      updatedAt
      name
      price
      currency
      country
      __typename
    }
    description
    benefits
    benefits_i18n
    promoAsset {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    coverAsset {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    activities {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    records {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    details
    details_i18n
    isFree
    numberOfSessions
    description_i18n
    isNative
    isActive
    isHidden
    enrollmentPackage {
      id
      createdAt
      updatedAt
      number
      onlyAI
      name
      name_i18n
      sessionDuration
      description
      benefits
      benefits_i18n
      details
      details_i18n
      isFree
      numberOfSessions
      description_i18n
      isNative
      isActive
      isHidden
      productIAP
      typePackage
      packageDuration
      discountDescription
      isRecommended
      sessionPerWeek
      __typename
    }
    promocodes {
      id
      createdAt
      updatedAt
      codeName
      isApplied
      sessionPerWeek
      __typename
    }
    productIAP
    typePackage
    packageDuration
    discountDescription
    isRecommended
    sessionPerWeek
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePackageMutationVariables,
  APITypes.UpdatePackageMutation
>;
export const deletePackage = /* GraphQL */ `mutation DeletePackage($id: ID!) {
  deletePackage(id: $id) {
    id
    createdAt
    updatedAt
    number
    onlyAI
    name
    name_i18n
    sessionDuration
    program {
      id
      createdAt
      updatedAt
      name
      name_i18n
      flag
      supportAi
      isActive
      __typename
    }
    price {
      id
      createdAt
      updatedAt
      name
      price
      currency
      country
      __typename
    }
    description
    benefits
    benefits_i18n
    promoAsset {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    coverAsset {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    activities {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    records {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    details
    details_i18n
    isFree
    numberOfSessions
    description_i18n
    isNative
    isActive
    isHidden
    enrollmentPackage {
      id
      createdAt
      updatedAt
      number
      onlyAI
      name
      name_i18n
      sessionDuration
      description
      benefits
      benefits_i18n
      details
      details_i18n
      isFree
      numberOfSessions
      description_i18n
      isNative
      isActive
      isHidden
      productIAP
      typePackage
      packageDuration
      discountDescription
      isRecommended
      sessionPerWeek
      __typename
    }
    promocodes {
      id
      createdAt
      updatedAt
      codeName
      isApplied
      sessionPerWeek
      __typename
    }
    productIAP
    typePackage
    packageDuration
    discountDescription
    isRecommended
    sessionPerWeek
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePackageMutationVariables,
  APITypes.DeletePackageMutation
>;
export const createPlan = /* GraphQL */ `mutation CreatePlan($input: PlanInsert!) {
  createPlan(input: $input)
}
` as GeneratedMutation<
  APITypes.CreatePlanMutationVariables,
  APITypes.CreatePlanMutation
>;
export const updatePlan = /* GraphQL */ `mutation UpdatePlan($id: ID!, $updatedAt: String!, $input: PlanUpdate!) {
  updatePlan(id: $id, updatedAt: $updatedAt, input: $input)
}
` as GeneratedMutation<
  APITypes.UpdatePlanMutationVariables,
  APITypes.UpdatePlanMutation
>;
export const deletePlan = /* GraphQL */ `mutation DeletePlan($id: ID!) {
  deletePlan(id: $id) {
    id
    createdAt
    updatedAt
    course {
      id
      createdAt
      updatedAt
      name
      plan
      __typename
    }
    d1 {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    d2 {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    d3 {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    d4 {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    d5 {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    d6 {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    d7 {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    d8 {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    d9 {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    d10 {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    d11 {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    d12 {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    d13 {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    d14 {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    d15 {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    d16 {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    d17 {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    d18 {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    d19 {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    d20 {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    d21 {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    d22 {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    d23 {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    d24 {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    d25 {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    d26 {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    d27 {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    d28 {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    d29 {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    d30 {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    d31 {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePlanMutationVariables,
  APITypes.DeletePlanMutation
>;
export const createParent = /* GraphQL */ `mutation CreateParent($input: ParentInsert!) {
  createParent(input: $input) {
    id
    createdAt
    updatedAt
    user {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      isOrganization
      __typename
    }
    name
    gender
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateParentMutationVariables,
  APITypes.CreateParentMutation
>;
export const updateParent = /* GraphQL */ `mutation UpdateParent($id: ID!, $updatedAt: String!, $input: ParentUpdate!) {
  updateParent(id: $id, updatedAt: $updatedAt, input: $input) {
    id
    createdAt
    updatedAt
    user {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      isOrganization
      __typename
    }
    name
    gender
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateParentMutationVariables,
  APITypes.UpdateParentMutation
>;
export const deleteParent = /* GraphQL */ `mutation DeleteParent($id: ID!) {
  deleteParent(id: $id) {
    id
    createdAt
    updatedAt
    user {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      isOrganization
      __typename
    }
    name
    gender
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteParentMutationVariables,
  APITypes.DeleteParentMutation
>;
export const createPrice = /* GraphQL */ `mutation CreatePrice($input: PriceInsert!) {
  createPrice(input: $input) {
    id
    createdAt
    updatedAt
    package {
      id
      createdAt
      updatedAt
      number
      onlyAI
      name
      name_i18n
      sessionDuration
      description
      benefits
      benefits_i18n
      details
      details_i18n
      isFree
      numberOfSessions
      description_i18n
      isNative
      isActive
      isHidden
      productIAP
      typePackage
      packageDuration
      discountDescription
      isRecommended
      sessionPerWeek
      __typename
    }
    name
    price
    currency
    country
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePriceMutationVariables,
  APITypes.CreatePriceMutation
>;
export const updatePrice = /* GraphQL */ `mutation UpdatePrice($id: ID!, $updatedAt: String!, $input: PriceUpdate!) {
  updatePrice(id: $id, updatedAt: $updatedAt, input: $input) {
    id
    createdAt
    updatedAt
    package {
      id
      createdAt
      updatedAt
      number
      onlyAI
      name
      name_i18n
      sessionDuration
      description
      benefits
      benefits_i18n
      details
      details_i18n
      isFree
      numberOfSessions
      description_i18n
      isNative
      isActive
      isHidden
      productIAP
      typePackage
      packageDuration
      discountDescription
      isRecommended
      sessionPerWeek
      __typename
    }
    name
    price
    currency
    country
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePriceMutationVariables,
  APITypes.UpdatePriceMutation
>;
export const deletePrice = /* GraphQL */ `mutation DeletePrice($id: ID!) {
  deletePrice(id: $id) {
    id
    createdAt
    updatedAt
    package {
      id
      createdAt
      updatedAt
      number
      onlyAI
      name
      name_i18n
      sessionDuration
      description
      benefits
      benefits_i18n
      details
      details_i18n
      isFree
      numberOfSessions
      description_i18n
      isNative
      isActive
      isHidden
      productIAP
      typePackage
      packageDuration
      discountDescription
      isRecommended
      sessionPerWeek
      __typename
    }
    name
    price
    currency
    country
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePriceMutationVariables,
  APITypes.DeletePriceMutation
>;
export const createProgram = /* GraphQL */ `mutation CreateProgram($input: ProgramInsert!) {
  createProgram(input: $input) {
    id
    createdAt
    updatedAt
    name
    name_i18n
    packages {
      id
      createdAt
      updatedAt
      number
      onlyAI
      name
      name_i18n
      sessionDuration
      description
      benefits
      benefits_i18n
      details
      details_i18n
      isFree
      numberOfSessions
      description_i18n
      isNative
      isActive
      isHidden
      productIAP
      typePackage
      packageDuration
      discountDescription
      isRecommended
      sessionPerWeek
      __typename
    }
    test {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    courses {
      id
      createdAt
      updatedAt
      name
      plan
      __typename
    }
    activities {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    records {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    flag
    supportAi
    isActive
    instructors {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProgramMutationVariables,
  APITypes.CreateProgramMutation
>;
export const updateProgram = /* GraphQL */ `mutation UpdateProgram($id: ID!, $updatedAt: String!, $input: ProgramUpdate!) {
  updateProgram(id: $id, updatedAt: $updatedAt, input: $input) {
    id
    createdAt
    updatedAt
    name
    name_i18n
    packages {
      id
      createdAt
      updatedAt
      number
      onlyAI
      name
      name_i18n
      sessionDuration
      description
      benefits
      benefits_i18n
      details
      details_i18n
      isFree
      numberOfSessions
      description_i18n
      isNative
      isActive
      isHidden
      productIAP
      typePackage
      packageDuration
      discountDescription
      isRecommended
      sessionPerWeek
      __typename
    }
    test {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    courses {
      id
      createdAt
      updatedAt
      name
      plan
      __typename
    }
    activities {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    records {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    flag
    supportAi
    isActive
    instructors {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProgramMutationVariables,
  APITypes.UpdateProgramMutation
>;
export const deleteProgram = /* GraphQL */ `mutation DeleteProgram($id: ID!) {
  deleteProgram(id: $id) {
    id
    createdAt
    updatedAt
    name
    name_i18n
    packages {
      id
      createdAt
      updatedAt
      number
      onlyAI
      name
      name_i18n
      sessionDuration
      description
      benefits
      benefits_i18n
      details
      details_i18n
      isFree
      numberOfSessions
      description_i18n
      isNative
      isActive
      isHidden
      productIAP
      typePackage
      packageDuration
      discountDescription
      isRecommended
      sessionPerWeek
      __typename
    }
    test {
      id
      createdAt
      updatedAt
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      __typename
    }
    courses {
      id
      createdAt
      updatedAt
      name
      plan
      __typename
    }
    activities {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    records {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    flag
    supportAi
    isActive
    instructors {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProgramMutationVariables,
  APITypes.DeleteProgramMutation
>;
export const createQuestion = /* GraphQL */ `mutation CreateQuestion($input: QuestionInsert!) {
  createQuestion(input: $input) {
    id
    createdAt
    updatedAt
    name
    name_i18n
    correctAsset {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    asset {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    videoAISessionUri
    choices
    customChoices
    programType
    keywords
    summary
    summary_i18n
    description
    description_i18n
    text
    textAI
    type
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateQuestionMutationVariables,
  APITypes.CreateQuestionMutation
>;
export const updateQuestion = /* GraphQL */ `mutation UpdateQuestion(
  $id: ID!
  $updatedAt: String!
  $input: QuestionUpdate!
) {
  updateQuestion(id: $id, updatedAt: $updatedAt, input: $input) {
    id
    createdAt
    updatedAt
    name
    name_i18n
    correctAsset {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    asset {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    videoAISessionUri
    choices
    customChoices
    programType
    keywords
    summary
    summary_i18n
    description
    description_i18n
    text
    textAI
    type
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateQuestionMutationVariables,
  APITypes.UpdateQuestionMutation
>;
export const deleteQuestion = /* GraphQL */ `mutation DeleteQuestion($id: ID!) {
  deleteQuestion(id: $id) {
    id
    createdAt
    updatedAt
    name
    name_i18n
    correctAsset {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    asset {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    videoAISessionUri
    choices
    customChoices
    programType
    keywords
    summary
    summary_i18n
    description
    description_i18n
    text
    textAI
    type
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteQuestionMutationVariables,
  APITypes.DeleteQuestionMutation
>;
export const createSchool = /* GraphQL */ `mutation CreateSchool($input: SchoolInsert!) {
  createSchool(input: $input) {
    id
    createdAt
    updatedAt
    nameArabic
    name
    students {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      isOrganization
      __typename
    }
    teachers {
      id
      createdAt
      updatedAt
      name
      userName
      email
      __typename
    }
    user {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      isOrganization
      __typename
    }
    isActive
    classesschool {
      id
      createdAt
      updatedAt
      name
      schoolYear
      __typename
    }
    promo {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSchoolMutationVariables,
  APITypes.CreateSchoolMutation
>;
export const updateSchool = /* GraphQL */ `mutation UpdateSchool($id: ID!, $updatedAt: String!, $input: SchoolUpdate!) {
  updateSchool(id: $id, updatedAt: $updatedAt, input: $input) {
    id
    createdAt
    updatedAt
    nameArabic
    name
    students {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      isOrganization
      __typename
    }
    teachers {
      id
      createdAt
      updatedAt
      name
      userName
      email
      __typename
    }
    user {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      isOrganization
      __typename
    }
    isActive
    classesschool {
      id
      createdAt
      updatedAt
      name
      schoolYear
      __typename
    }
    promo {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSchoolMutationVariables,
  APITypes.UpdateSchoolMutation
>;
export const deleteSchool = /* GraphQL */ `mutation DeleteSchool($id: ID!) {
  deleteSchool(id: $id) {
    id
    createdAt
    updatedAt
    nameArabic
    name
    students {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      isOrganization
      __typename
    }
    teachers {
      id
      createdAt
      updatedAt
      name
      userName
      email
      __typename
    }
    user {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      isOrganization
      __typename
    }
    isActive
    classesschool {
      id
      createdAt
      updatedAt
      name
      schoolYear
      __typename
    }
    promo {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSchoolMutationVariables,
  APITypes.DeleteSchoolMutation
>;
export const createSession = /* GraphQL */ `mutation CreateSession($input: SessionInsert!) {
  createSession(input: $input) {
    id
    createdAt
    updatedAt
    name
    slot {
      id
      createdAt
      updatedAt
      name
      slotDuration
      startTime
      endTime
      status
      limit
      current
      day
      __typename
    }
    duration
    startDateTime
    startTime
    endTime
    status
    isRescheduled
    instructor {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    lesson {
      id
      createdAt
      updatedAt
      name
      __typename
    }
    moderatorToken
    jitsiUrl
    rtcUserToken
    rtcInstructorToken
    rtcShareScreenToken
    rtcAdminToken
    zoomUrl
    group {
      id
      createdAt
      updatedAt
      startDate
      name
      division
      limit
      isGroupHasInstructor
      currentSessionNumber
      isActive
      __typename
    }
    memberships {
      id
      createdAt
      updatedAt
      paymentStatus
      placementStatus
      isActive
      startDate
      endDate
      sessionPerWeek
      isFullFilled
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSessionMutationVariables,
  APITypes.CreateSessionMutation
>;
export const updateSession = /* GraphQL */ `mutation UpdateSession($id: ID!, $updatedAt: String!, $input: SessionUpdate!) {
  updateSession(id: $id, updatedAt: $updatedAt, input: $input) {
    id
    createdAt
    updatedAt
    name
    slot {
      id
      createdAt
      updatedAt
      name
      slotDuration
      startTime
      endTime
      status
      limit
      current
      day
      __typename
    }
    duration
    startDateTime
    startTime
    endTime
    status
    isRescheduled
    instructor {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    lesson {
      id
      createdAt
      updatedAt
      name
      __typename
    }
    moderatorToken
    jitsiUrl
    rtcUserToken
    rtcInstructorToken
    rtcShareScreenToken
    rtcAdminToken
    zoomUrl
    group {
      id
      createdAt
      updatedAt
      startDate
      name
      division
      limit
      isGroupHasInstructor
      currentSessionNumber
      isActive
      __typename
    }
    memberships {
      id
      createdAt
      updatedAt
      paymentStatus
      placementStatus
      isActive
      startDate
      endDate
      sessionPerWeek
      isFullFilled
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSessionMutationVariables,
  APITypes.UpdateSessionMutation
>;
export const resetSessionStatus = /* GraphQL */ `mutation ResetSessionStatus($id: ID!, $updatedAt: String!, $status: Status!) {
  resetSessionStatus(id: $id, updatedAt: $updatedAt, status: $status) {
    id
    createdAt
    updatedAt
    name
    slot {
      id
      createdAt
      updatedAt
      name
      slotDuration
      startTime
      endTime
      status
      limit
      current
      day
      __typename
    }
    duration
    startDateTime
    startTime
    endTime
    status
    isRescheduled
    instructor {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    lesson {
      id
      createdAt
      updatedAt
      name
      __typename
    }
    moderatorToken
    jitsiUrl
    rtcUserToken
    rtcInstructorToken
    rtcShareScreenToken
    rtcAdminToken
    zoomUrl
    group {
      id
      createdAt
      updatedAt
      startDate
      name
      division
      limit
      isGroupHasInstructor
      currentSessionNumber
      isActive
      __typename
    }
    memberships {
      id
      createdAt
      updatedAt
      paymentStatus
      placementStatus
      isActive
      startDate
      endDate
      sessionPerWeek
      isFullFilled
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ResetSessionStatusMutationVariables,
  APITypes.ResetSessionStatusMutation
>;
export const deleteSession = /* GraphQL */ `mutation DeleteSession($id: ID!) {
  deleteSession(id: $id) {
    id
    createdAt
    updatedAt
    name
    slot {
      id
      createdAt
      updatedAt
      name
      slotDuration
      startTime
      endTime
      status
      limit
      current
      day
      __typename
    }
    duration
    startDateTime
    startTime
    endTime
    status
    isRescheduled
    instructor {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    lesson {
      id
      createdAt
      updatedAt
      name
      __typename
    }
    moderatorToken
    jitsiUrl
    rtcUserToken
    rtcInstructorToken
    rtcShareScreenToken
    rtcAdminToken
    zoomUrl
    group {
      id
      createdAt
      updatedAt
      startDate
      name
      division
      limit
      isGroupHasInstructor
      currentSessionNumber
      isActive
      __typename
    }
    memberships {
      id
      createdAt
      updatedAt
      paymentStatus
      placementStatus
      isActive
      startDate
      endDate
      sessionPerWeek
      isFullFilled
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSessionMutationVariables,
  APITypes.DeleteSessionMutation
>;
export const createSlot = /* GraphQL */ `mutation CreateSlot($input: SlotInsert!) {
  createSlot(input: $input) {
    id
    createdAt
    updatedAt
    name
    slotDuration
    availability {
      id
      createdAt
      updatedAt
      startTime
      endTime
      day
      __typename
    }
    program {
      id
      createdAt
      updatedAt
      name
      name_i18n
      flag
      supportAi
      isActive
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    startTime
    endTime
    status
    limit
    current
    day
    group {
      id
      createdAt
      updatedAt
      startDate
      name
      division
      limit
      isGroupHasInstructor
      currentSessionNumber
      isActive
      __typename
    }
    instructor {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSlotMutationVariables,
  APITypes.CreateSlotMutation
>;
export const updateSlot = /* GraphQL */ `mutation UpdateSlot($id: ID!, $updatedAt: String!, $input: SlotUpdate!) {
  updateSlot(id: $id, updatedAt: $updatedAt, input: $input) {
    id
    createdAt
    updatedAt
    name
    slotDuration
    availability {
      id
      createdAt
      updatedAt
      startTime
      endTime
      day
      __typename
    }
    program {
      id
      createdAt
      updatedAt
      name
      name_i18n
      flag
      supportAi
      isActive
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    startTime
    endTime
    status
    limit
    current
    day
    group {
      id
      createdAt
      updatedAt
      startDate
      name
      division
      limit
      isGroupHasInstructor
      currentSessionNumber
      isActive
      __typename
    }
    instructor {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSlotMutationVariables,
  APITypes.UpdateSlotMutation
>;
export const deleteSlot = /* GraphQL */ `mutation DeleteSlot($id: ID!) {
  deleteSlot(id: $id) {
    id
    createdAt
    updatedAt
    name
    slotDuration
    availability {
      id
      createdAt
      updatedAt
      startTime
      endTime
      day
      __typename
    }
    program {
      id
      createdAt
      updatedAt
      name
      name_i18n
      flag
      supportAi
      isActive
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    startTime
    endTime
    status
    limit
    current
    day
    group {
      id
      createdAt
      updatedAt
      startDate
      name
      division
      limit
      isGroupHasInstructor
      currentSessionNumber
      isActive
      __typename
    }
    instructor {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSlotMutationVariables,
  APITypes.DeleteSlotMutation
>;
export const createStudent = /* GraphQL */ `mutation CreateStudent($input: StudentInsert!) {
  createStudent(input: $input) {
    id
    createdAt
    updatedAt
    user {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      isOrganization
      __typename
    }
    schoolName
    name
    birthDate
    age
    targetAge
    gender
    language {
      id
      createdAt
      updatedAt
      language
      country
      __typename
    }
    notes
    facebookProfileUrl
    enrollments {
      id
      createdAt
      updatedAt
      placementStatus
      paymentStatus
      startDate
      endDate
      selfRating
      __typename
    }
    memberships {
      id
      createdAt
      updatedAt
      paymentStatus
      placementStatus
      isActive
      startDate
      endDate
      sessionPerWeek
      isFullFilled
      __typename
    }
    statistic {
      id
      createdAt
      updatedAt
      scores
      averageScore
      fullReportLastGeneratedDate
      phoneticsAsString
      syllablesAsString
      __typename
    }
    studentaudioprofile {
      id
      createdAt
      updatedAt
      introAIVSUri
      sentenceIntro
      correctAIVSUri
      sentenceCorrect
      failAIVSUri
      sentenceFail
      finalAIVSUri
      sentenceFinal
      studentName
      __typename
    }
    status
    character
    assignedTo
    sessions {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    studentaisessions {
      id
      createdAt
      updatedAt
      status
      currentQuestionIndex
      __typename
    }
    studentconversations {
      createdAt
      updatedAt
      id
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    school {
      id
      createdAt
      updatedAt
      nameArabic
      name
      isActive
      __typename
    }
    classschool {
      id
      createdAt
      updatedAt
      name
      schoolYear
      __typename
    }
    isSchoolVerfied
    placementStatus
    email
    userName
    totalRatingConcentration
    totalRatingCamera
    totalRatingInternet
    totalRatingSessionQuality
    totalRating
    totalReviews
    isOrganization
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateStudentMutationVariables,
  APITypes.CreateStudentMutation
>;
export const updateStudent = /* GraphQL */ `mutation UpdateStudent($id: ID!, $updatedAt: String!, $input: StudentUpdate!) {
  updateStudent(id: $id, updatedAt: $updatedAt, input: $input) {
    id
    createdAt
    updatedAt
    user {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      isOrganization
      __typename
    }
    schoolName
    name
    birthDate
    age
    targetAge
    gender
    language {
      id
      createdAt
      updatedAt
      language
      country
      __typename
    }
    notes
    facebookProfileUrl
    enrollments {
      id
      createdAt
      updatedAt
      placementStatus
      paymentStatus
      startDate
      endDate
      selfRating
      __typename
    }
    memberships {
      id
      createdAt
      updatedAt
      paymentStatus
      placementStatus
      isActive
      startDate
      endDate
      sessionPerWeek
      isFullFilled
      __typename
    }
    statistic {
      id
      createdAt
      updatedAt
      scores
      averageScore
      fullReportLastGeneratedDate
      phoneticsAsString
      syllablesAsString
      __typename
    }
    studentaudioprofile {
      id
      createdAt
      updatedAt
      introAIVSUri
      sentenceIntro
      correctAIVSUri
      sentenceCorrect
      failAIVSUri
      sentenceFail
      finalAIVSUri
      sentenceFinal
      studentName
      __typename
    }
    status
    character
    assignedTo
    sessions {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    studentaisessions {
      id
      createdAt
      updatedAt
      status
      currentQuestionIndex
      __typename
    }
    studentconversations {
      createdAt
      updatedAt
      id
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    school {
      id
      createdAt
      updatedAt
      nameArabic
      name
      isActive
      __typename
    }
    classschool {
      id
      createdAt
      updatedAt
      name
      schoolYear
      __typename
    }
    isSchoolVerfied
    placementStatus
    email
    userName
    totalRatingConcentration
    totalRatingCamera
    totalRatingInternet
    totalRatingSessionQuality
    totalRating
    totalReviews
    isOrganization
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateStudentMutationVariables,
  APITypes.UpdateStudentMutation
>;
export const deleteStudent = /* GraphQL */ `mutation DeleteStudent($id: ID!) {
  deleteStudent(id: $id) {
    id
    createdAt
    updatedAt
    user {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      deviceToken
      devicePlatform
      endPointArn
      email
      contactIDActiveCampaign
      partnerAppName
      isOrganization
      __typename
    }
    schoolName
    name
    birthDate
    age
    targetAge
    gender
    language {
      id
      createdAt
      updatedAt
      language
      country
      __typename
    }
    notes
    facebookProfileUrl
    enrollments {
      id
      createdAt
      updatedAt
      placementStatus
      paymentStatus
      startDate
      endDate
      selfRating
      __typename
    }
    memberships {
      id
      createdAt
      updatedAt
      paymentStatus
      placementStatus
      isActive
      startDate
      endDate
      sessionPerWeek
      isFullFilled
      __typename
    }
    statistic {
      id
      createdAt
      updatedAt
      scores
      averageScore
      fullReportLastGeneratedDate
      phoneticsAsString
      syllablesAsString
      __typename
    }
    studentaudioprofile {
      id
      createdAt
      updatedAt
      introAIVSUri
      sentenceIntro
      correctAIVSUri
      sentenceCorrect
      failAIVSUri
      sentenceFail
      finalAIVSUri
      sentenceFinal
      studentName
      __typename
    }
    status
    character
    assignedTo
    sessions {
      id
      createdAt
      updatedAt
      name
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      moderatorToken
      jitsiUrl
      rtcUserToken
      rtcInstructorToken
      rtcShareScreenToken
      rtcAdminToken
      zoomUrl
      __typename
    }
    studentaisessions {
      id
      createdAt
      updatedAt
      status
      currentQuestionIndex
      __typename
    }
    studentconversations {
      createdAt
      updatedAt
      id
      __typename
    }
    level {
      id
      createdAt
      updatedAt
      name
      number
      score
      __typename
    }
    school {
      id
      createdAt
      updatedAt
      nameArabic
      name
      isActive
      __typename
    }
    classschool {
      id
      createdAt
      updatedAt
      name
      schoolYear
      __typename
    }
    isSchoolVerfied
    placementStatus
    email
    userName
    totalRatingConcentration
    totalRatingCamera
    totalRatingInternet
    totalRatingSessionQuality
    totalRating
    totalReviews
    isOrganization
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteStudentMutationVariables,
  APITypes.DeleteStudentMutation
>;
export const createHomework = /* GraphQL */ `mutation CreateHomework($input: HomeworkInsert!) {
  createHomework(input: $input) {
    id
    createdAt
    updatedAt
    expiryDate
    isActive
    teacher {
      id
      createdAt
      updatedAt
      name
      userName
      email
      __typename
    }
    classschool {
      id
      createdAt
      updatedAt
      name
      schoolYear
      __typename
    }
    question {
      id
      createdAt
      updatedAt
      name
      name_i18n
      videoAISessionUri
      choices
      customChoices
      programType
      keywords
      summary
      summary_i18n
      description
      description_i18n
      text
      textAI
      type
      __typename
    }
    answers {
      id
      createdAt
      updatedAt
      choice
      given
      type
      audioUri
      text
      score
      analysis
      analysisChoices
      useEnglishAi
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateHomeworkMutationVariables,
  APITypes.CreateHomeworkMutation
>;
export const updateHomework = /* GraphQL */ `mutation UpdateHomework(
  $id: ID!
  $updatedAt: String!
  $input: HomeworkUpdate!
) {
  updateHomework(id: $id, updatedAt: $updatedAt, input: $input) {
    id
    createdAt
    updatedAt
    expiryDate
    isActive
    teacher {
      id
      createdAt
      updatedAt
      name
      userName
      email
      __typename
    }
    classschool {
      id
      createdAt
      updatedAt
      name
      schoolYear
      __typename
    }
    question {
      id
      createdAt
      updatedAt
      name
      name_i18n
      videoAISessionUri
      choices
      customChoices
      programType
      keywords
      summary
      summary_i18n
      description
      description_i18n
      text
      textAI
      type
      __typename
    }
    answers {
      id
      createdAt
      updatedAt
      choice
      given
      type
      audioUri
      text
      score
      analysis
      analysisChoices
      useEnglishAi
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateHomeworkMutationVariables,
  APITypes.UpdateHomeworkMutation
>;
export const deleteHomework = /* GraphQL */ `mutation DeleteHomework($id: ID!) {
  deleteHomework(id: $id) {
    id
    createdAt
    updatedAt
    expiryDate
    isActive
    teacher {
      id
      createdAt
      updatedAt
      name
      userName
      email
      __typename
    }
    classschool {
      id
      createdAt
      updatedAt
      name
      schoolYear
      __typename
    }
    question {
      id
      createdAt
      updatedAt
      name
      name_i18n
      videoAISessionUri
      choices
      customChoices
      programType
      keywords
      summary
      summary_i18n
      description
      description_i18n
      text
      textAI
      type
      __typename
    }
    answers {
      id
      createdAt
      updatedAt
      choice
      given
      type
      audioUri
      text
      score
      analysis
      analysisChoices
      useEnglishAi
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteHomeworkMutationVariables,
  APITypes.DeleteHomeworkMutation
>;
export const createTest = /* GraphQL */ `mutation CreateTest($input: TestInsert!) {
  createTest(input: $input) {
    id
    createdAt
    updatedAt
    questions {
      id
      createdAt
      updatedAt
      name
      name_i18n
      videoAISessionUri
      choices
      customChoices
      programType
      keywords
      summary
      summary_i18n
      description
      description_i18n
      text
      textAI
      type
      __typename
    }
    type
    name
    name_i18n
    description
    description_i18n
    summary
    summary_i18n
    asset {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    lesson {
      id
      createdAt
      updatedAt
      name
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTestMutationVariables,
  APITypes.CreateTestMutation
>;
export const updateTest = /* GraphQL */ `mutation UpdateTest($id: ID!, $updatedAt: String!, $input: TestUpdate!) {
  updateTest(id: $id, updatedAt: $updatedAt, input: $input) {
    id
    createdAt
    updatedAt
    questions {
      id
      createdAt
      updatedAt
      name
      name_i18n
      videoAISessionUri
      choices
      customChoices
      programType
      keywords
      summary
      summary_i18n
      description
      description_i18n
      text
      textAI
      type
      __typename
    }
    type
    name
    name_i18n
    description
    description_i18n
    summary
    summary_i18n
    asset {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    lesson {
      id
      createdAt
      updatedAt
      name
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTestMutationVariables,
  APITypes.UpdateTestMutation
>;
export const deleteTest = /* GraphQL */ `mutation DeleteTest($id: ID!) {
  deleteTest(id: $id) {
    id
    createdAt
    updatedAt
    questions {
      id
      createdAt
      updatedAt
      name
      name_i18n
      videoAISessionUri
      choices
      customChoices
      programType
      keywords
      summary
      summary_i18n
      description
      description_i18n
      text
      textAI
      type
      __typename
    }
    type
    name
    name_i18n
    description
    description_i18n
    summary
    summary_i18n
    asset {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
      __typename
    }
    lesson {
      id
      createdAt
      updatedAt
      name
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTestMutationVariables,
  APITypes.DeleteTestMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser($input: UserInsert!) {
  createUser(input: $input) {
    id
    createdAt
    updatedAt
    cognitoUserId
    country
    state
    whatsappNumber
    timeZone
    firstName
    lastName
    parent {
      id
      createdAt
      updatedAt
      name
      gender
      __typename
    }
    admin {
      id
      createdAt
      updatedAt
      lastSeenOnline
      firstName
      lastName
      address
      SSN
      gender
      isActive
      __typename
    }
    instructor {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    students {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      isOrganization
      __typename
    }
    school {
      id
      createdAt
      updatedAt
      nameArabic
      name
      isActive
      __typename
    }
    teacher {
      id
      createdAt
      updatedAt
      name
      userName
      email
      __typename
    }
    userconversations {
      createdAt
      updatedAt
      id
      __typename
    }
    deviceToken
    devicePlatform
    endPointArn
    email
    contactIDActiveCampaign
    partnerAppName
    preInstructor {
      id
      createdAt
      updatedAt
      name
      gender
      rating
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      isActive
      status
      birthDate
      __typename
    }
    isOrganization
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser($id: ID!, $updatedAt: String!, $input: UserUpdate!) {
  updateUser(id: $id, updatedAt: $updatedAt, input: $input) {
    id
    createdAt
    updatedAt
    cognitoUserId
    country
    state
    whatsappNumber
    timeZone
    firstName
    lastName
    parent {
      id
      createdAt
      updatedAt
      name
      gender
      __typename
    }
    admin {
      id
      createdAt
      updatedAt
      lastSeenOnline
      firstName
      lastName
      address
      SSN
      gender
      isActive
      __typename
    }
    instructor {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    students {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      isOrganization
      __typename
    }
    school {
      id
      createdAt
      updatedAt
      nameArabic
      name
      isActive
      __typename
    }
    teacher {
      id
      createdAt
      updatedAt
      name
      userName
      email
      __typename
    }
    userconversations {
      createdAt
      updatedAt
      id
      __typename
    }
    deviceToken
    devicePlatform
    endPointArn
    email
    contactIDActiveCampaign
    partnerAppName
    preInstructor {
      id
      createdAt
      updatedAt
      name
      gender
      rating
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      isActive
      status
      birthDate
      __typename
    }
    isOrganization
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser($id: ID!) {
  deleteUser(id: $id) {
    id
    createdAt
    updatedAt
    cognitoUserId
    country
    state
    whatsappNumber
    timeZone
    firstName
    lastName
    parent {
      id
      createdAt
      updatedAt
      name
      gender
      __typename
    }
    admin {
      id
      createdAt
      updatedAt
      lastSeenOnline
      firstName
      lastName
      address
      SSN
      gender
      isActive
      __typename
    }
    instructor {
      id
      createdAt
      updatedAt
      name
      gender
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      paymentMethod
      rating
      isActive
      status
      birthDate
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      __typename
    }
    students {
      id
      createdAt
      updatedAt
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      status
      character
      assignedTo
      isSchoolVerfied
      placementStatus
      email
      userName
      totalRatingConcentration
      totalRatingCamera
      totalRatingInternet
      totalRatingSessionQuality
      totalRating
      totalReviews
      isOrganization
      __typename
    }
    school {
      id
      createdAt
      updatedAt
      nameArabic
      name
      isActive
      __typename
    }
    teacher {
      id
      createdAt
      updatedAt
      name
      userName
      email
      __typename
    }
    userconversations {
      createdAt
      updatedAt
      id
      __typename
    }
    deviceToken
    devicePlatform
    endPointArn
    email
    contactIDActiveCampaign
    partnerAppName
    preInstructor {
      id
      createdAt
      updatedAt
      name
      gender
      rating
      sessionsCount
      speakingLanguages
      isNative
      totalRating
      totalReviews
      isActive
      status
      birthDate
      __typename
    }
    isOrganization
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
